/* eslint-disable quotes */
import { HeadProps } from 'u9/components/Head/Head';
import { ClubhouseCopy, ExperienceCopy, GlobalCopy } from './copy.types';

export const placeholderHeadCopy: HeadProps = {
  title: 'Worlds 2021 Anthem Experience',
  description: 'Explore the world and inspiration behind the LoL Esports Worlds 2021 Anthem & Music Video.',
  ogType: 'website',
  ogImage: { url: '' }
};

export const placeholderGlobalCopy: GlobalCopy = {
  "cookies": {
    "title": "Cookies",
    "description": "We and <a href=\"#\" target=\"_blank\">our partners</a> use cookies to personalize your experience, to show you ads based on your interests, and for measurement and analytics purposes. By using our website and services, you agree to our use of cookies as described in our <a href=\"#\" target=\"_blank\">Cookie Policy</a>.",
    "ctaCopy": "Accept"
  },
  "errors": {
    "notFound": {
      "title": "404",
      "body": "Sorry, something's gone wrong.\nWe can't find what you're looking for.",
      "cta": "Back to Home"
    },
    "javascriptDisabled": {
      "title": "ENABLE <span>\nJAVASCRIPT</span>",
      "body1": "On Safari, go to:<span>\nSafari Preference > Advanced</span>",
      "body2": "On Chrome, go to:<span>\nChrome Settings > Site Settings</span>"
    },
    "rotateDevice": {
      "title": "ROTATE <span>DEVICE</span>",
      "body": {
        "mobile": "Switch your device to portrait mode to play",
        "tablet": "Switch your device to landscape mode to play"
      }
    },
    "unsupportedBrowser": {
      "title": "Warning",
      "body": {
        "mobile": "This experience is optimised for <span>Safari (iOS) and Chrome (Android).\n\nWe recommend that you use one of these browsers.</span>",
        "desktop": "This experience works best with <span>Safari 13+ (MacOS Catalina), Chrome 92+, Safari 14+, Firefox 90+ (MacOS Big Sur) and Chrome 92+, Firefox 90+, Microsoft Edge 92+ (Windows).\n\nWe recommend that you use one of these browsers.</span>",
        "social": "This experience is optimised for <span>Safari (iOS) and Chrome (Android).\nWe recommend that you use one of these browsers.\n\nYou can open this site directly in your browser by clicking the menu button in either the top-right or bottom-right of the screen.</span>",
        "banner": "For the best experience, switch to a supported browser."
      },
      "cta": "Continue anyway"
    },
    "windowTooSmall": {
      "title": "RESIZE <span>\nBROWSER</span>",
      "body": "Your browser is too small, the resolution needs to be larger to enter this website. <span>Please resize to continue.</span>"
    },
    "webGLDisabled": {
      "title": "SORRY!",
      "body": "We cannot detect WebGL in your browser. <span>Please enable WebGL or switch to a desktop browser or device that supports WebGL to continue.</span>"
    }
  }
};

export const placeholderExperienceCopy: ExperienceCopy = {
  "player": {
    "connect": "Connect Spotify",
    "removeFavorite": "Favorited",
    "addFavorite": "Add to Favorites",
    "premiumLabel": "Get Spotify Premium",
    "premiumLink": "https://www.spotify.com/premium"
  },
  "personalizedPlaylist": {
    "card": {
      "billboard1": "MUSIC",
      "billboard2": "ADDED",
      "ribbon_moving": "BURN IT ALL DOWN  /  PVRIS   //   MAKE  /  BREAK ///",
      "ribbon_static": "Go to your playlist"
    },
    "overlay": {
      "header": "YOUR PLAYLIST",
      "title": "THE <span>MUSIC</span> BEHIND THE <span>WORLD</span>",
      "description": "Discover new music as you explore all three Clubhouses. Connect to Spotify to save your favorite tracks and get personalised music recomendations.",
      "notLogged": {
        "cta": "Connect Spotify"
      },
      "logged": {
        "cta1_noPlaylist": "SAVE PLAYLIST",
        "cta1_playlist": "UPDATE PLAYLIST",
        "cta2": "SHARE PLAYLIST",
        "favoritePlaylistTitle": "[username]’s Worlds Anthem Playlist",
        "favoritePlaylistDescription": "Favorite tracks, inspired by the Worlds Anthem.",
        "updateConfirm": "Spotify playlist updated",
        "clipboardConfirm": "Link copied to clipboard"
      },
      "playlists": {
        "favorites": {
          "title": "Your Favorites",
          "none_heading": "You haven’t added any tracks yet",
          "none_description": "Favorite tracks to add them to your playlist"
        },
        "recommended": {
          "title": "Spotify Recommends",
          "description": "A personal selection of tracks recommended just for you.",
          "none_heading": "No recommendations yet",
          "none_description": "Weʼll recommend 10 personalized tracks based on music youʼve favorited."
        }
      }
    }
  },
  "landingPage": {
    "intro": "Presents",
    "transition": "Uncover the\nstory within the <span>Worlds Anthem</span>",
    "header": "AN IMMERSIVE WORLDS ANTHEM EXPERIENCE",
    "billboard": "BURN IT ALL DOWN / PVRIS / BURN IT ALL DOWN / PVRIS",
    "ribbon": "BURN IT ALL DOWN / PVRIS // MAKE / BREAK",
    "ribbon_instruction": "Select a Clubhouse to explore",
    "clubhouses": {
      "seoul": {
        "city": "SEOUL",
        "country": "Korea",
        "billboard": "LCK CLUBHOUSE /",
        "ribbon": "LCK / KOREA / SHOWMAKER"
      },
      "berlin": {
        "city": "BERLIN",
        "country": "Germany",
        "billboard": "LEC CLUBHOUSE /",
        "ribbon": "LEC / GERMANY / REKKLES"
      },
      "shanghai": {
        "city": "SHANGHAI",
        "country": "China",
        "billboard": "LPL CLUBHOUSE /",
        "ribbon": "LPL / CHINA / JACKEYLOVE"
      }
    }
  },
  "hotspotsOverlays": {
    "scroll": "SCROLL",
    "prev": "PREV",
    "next": "NEXT",
    "view": {
      "mobile": "TOUCH & HOLD TO GET BEHIND BURN IT ALL DOWN",
      "desktop": "PRESS & HOLD TO GET BEHIND BURN IT ALL DOWN",
      "desktopClick": "WATCH TO GET BEHIND BURN IT ALL DOWN"
    },
    "download": "DOWNLOAD",
    "seeMore": "SEE FULL PROFILE"
  },
  "clubhouseEntry": {
    "hotspot": "Select hotspot to <span>explore</span>",
    "entry": "Select arrow to <span>enter Clubhouse</span>",
    "skip": "Skip Onboarding",
    "getStarted": "Get started",
    "dragInstruction": "<span>Drag</span> to view the full scene",
    "prompts": {
      "favorite_logged": "Favorite tracks as you explore",
      "favorite_notLogged": "Connect Spotify to favorite tracks as you explore",
      "playlist": "Open Your Playlist to see your favorited tracks"
    }
  },
  "authPrompt": {
    "connect": {
      "subHeading": "Connect Spotify",
      "title": "Discover the music behind the <span>Worlds Anthem</span>",
      "description": "Find your new favorite tracks as you explore the Worlds Anthem. For the best experience, connect to Spotify and continue.",
      "cta": "Connect Spotify"
    },
    "personalized": {
      "subHeading": "Connect Spotify",
      "title": "Your  <span>Worlds Anthem </span> playlist",
      "description": "When you connect to Spotify, you can save your favorite tracks and get personalised music recommendations.",
      "cta": "Connect Spotify"
    }
  },
  "header": {
    "spotifyCta_notOnboarded": "CONNECT SPOTIFY",
    "spotifyCta_onboarded": "YOUR PLAYLIST",
    "exit": "Exit",
    "back": "Back"
  },
  "footer": {
    "videoCta": {
      "mobile": "WATCH THE ANTHEM",
      "desktop": "Watch"
    },
    "languages": [
      {
        "long": "English",
        "short": "EN",
        "code": "en"
      },
      {
        "long": "Español",
        "short": "ES",
        "code": "es"
      },
      {
        "long": "한국어",
        "short": "한국어",
        "code": "ko"
      }
    ],
    "credits1": "Worlds 2021",
    "credits1_link": "https://lolesports.com",
    "legal": "Legal",
    "legal_menu": [
      {
        "label": "Legal",
        "link": "https://www.spotify.com/legal"
      },
      {
        "label": "Privacy center",
        "link": "https://www.spotify.com/privacy"
      },
      {
        "label": "Privacy policy",
        "link": "https://www.spotify.com/legal/privacy-policy"
      },
      {
        "label": "Cookies",
        "link": "https://www.spotify.com/legal/cookies-policy"
      }
    ],
    "credits2": "© 2021 Spotify"
  },
  "loading": {
    "billboard": "LOADING"
  },
  "videoPlayer": {
    "header": "BURN IT ALL DOWN",
    "cta": "LISTEN ON SPOTIFY"
  }
};

export const placeholderClubhouseCopy: ClubhouseCopy = {
  "footer": {
    "entry": "<span>SEOUL,</span> KOREA",
    "rooms": [
      "<span>TRAINING</span> ROOM",
      "<span>ANALYSIS</span> LAB"
    ]
  },
  "transition": {
    "billboard1": "어서 오십시오",
    "billboard2": "WELCOME"
  },
  "intro": {
    "title": "MAKE/BREAK SOCIETY",
    "subtitle": "THE <span>LCK</span> CLUBHOUSE",
    "description": "Home of Showmaker & Korea’s finest"
  },
  "hotspots": {
    "storytelling": [
      "<span>ACT 1</span>THE <span>CALL</span>",
      "<span>ACT 2</span>THE <span>SPARK</span>",
      "<span>ACT 3</span>THE <span>FIRE</span> RAGES"
    ],
    "pro": "Meet the <span>Pros</span>",
    "champion": "Featured <span>Champion</span>",
    "skill": "<span>Skill</span> Building",
    "easterEgg": "Easter <span>Egg</span>",
    "location": [
      "<span>TRAINING</span> ROOM",
      "<span>ANALYSIS</span> LAB"
    ]
  },
  "hotspotsOverlays": {
    "storytelling": [
      {
        "page1": {
          "title": "ACT 1: THE CALL",
          "subtitle": "SHOWMAKER <br /><span>// SEOUL</span>",
          "body": "In the busy streets of Seoul, Showmaker finds himself overshadowed by the Unkillable Demon King. Even after his Worlds 2020 win, he wonders how to cement his legend. Until a mysterious symbol beckons.",
          "breadcrumb": "THE STORY"
        },
        "page2": {
          "subtitle": "A HIDDEN DOOR, A SECRET <span>SOCIETY</span>",
          "bodyIntro": "Amidst the neighborhoods of Korea that sprawl beyond the city center, a door emerges. The door, reflecting Korea’s heritage, beckons Showmaker forwards.",
          "body": "Intentionally, this door does not echo the modern and innovative design beyond. Here, the tradition of Korea makes way for the future of Korea.",
          "musicIntro": "The sound of Seoul",
          "breadcrumb": "THE WORLD"
        },
        "page3": {
          "body": "The eyes were critical; conveying a depth of intelligence and gaze that missed no detail. The trademark “frown” gave him that iconic Showmaker look.",
          "breadcrumb": "THE PRO",
          "trivia": "“I decided on [my Summoner name] Showmaker as my way to say: I want to put on a great show.”",
          "musicIntro": "If Showmaker was a song he’d be:",
          "triviaIntro": "The story behind Showmaker:",
          "bodyIntro": "The character needed to be worthy of Showmaker; a World Champion and leader of the LCK’s next generation. It needed to embody his inner strength, razor-sharp mind, and unique sense of self.",
          "subtitle": "CREATING <span>SHOWMAKER</span>"
        },
        "page4": {
          "body": "When approached about the track, PVRIS loved the vision and enthusiastically joined the project. The muse became the voice.",
          "breadcrumb": "THE MUSIC",
          "trivia": "“I was incredibly grateful and excited! This opportunity came up a few years ago, but scheduling didn’t work out... Now, this feels like the right moment and the perfect time – it all worked out.”",
          "musicIntro": "PVRIS’ latest single:",
          "triviaIntro": "PVRIS’ take:",
          "bodyIntro": "“Burn It All Down” channels honest vulnerability and explosive strength. It demanded an artist who could authentically harness both of those emotions. PVRISʼ voice was the initial inspiration.",
          "subtitle": "FINDING <span>PVRIS</span>"
        }
      },
      {
        "page1": {
          "title": "ACT 2: THE SPARK",
          "subtitle": "TWO STARS <br /><span>// ONE FUTURE KING</span>",
          "body": "Showmaker and Chovy face off on the fighting floor. Seasons of rivalry and a mutual desire to become the future king of the LCK – and of the world – burn between them! Now, the two star midlaners fight.",
          "breadcrumb": "THE STORY"
        },
        "page2": {
          "subtitle": "PREMIUM, PRESTIGIOUS, <span>INNOVATIVE</span>",
          "bodyIntro": "The winningest region in all of Worlds history, the LCK clubhouse embodies prestige. Its expansive interior is filled with the equipment a pro needs to train to become the best.",
          "body": "Tradition and modernity collide. The latest tech and industrial design reflects Seoul as a modern city. The fighting floor and ceiling pay homage to Korea’s storied past with traditional tiling and details.",
          "musicIntro": "The sound of LCK",
          "breadcrumb": "THE WORLD"
        },
        "page3": {
          "body": "Showmaker is finding the strength to carry the mantle of the LCK, learning he’s worthy of stepping beyond Faker’s shadow. He’s building courage to break the past and make the future.",
          "breadcrumb": "THE PRO",
          "trivia": "While training in season, Showmaker will spend 8-9 hours in practice before joining his team in the evening for their training matches.",
          "musicIntro": "Showmaker’s personal anthem",
          "triviaIntro": "Fun fact",
          "bodyIntro": "Training is never simple. Through his face off with Chovy and his analysis sessions, Showmaker is digging deep. His skills have never been in question. But his belief in himself? That takes work.",
          "subtitle": "<span>SHOWMAKERʼS</span> INNER JOURNEY"
        },
        "page4": {
          "body": "PVRIS had studied up, but she talked with the team about the lyrics and story to help shape her approach to the song. The result? A uniquely powerful performance.",
          "breadcrumb": "THE MUSIC",
          "trivia": "“I typically do a basic 15-20 minute warm up before I record. But this time I just rolled up to the studio, chugged two cold brews, and jumped right in!”",
          "musicIntro": "Worlds Anthem 2021",
          "triviaIntro": "PVRIS’ take:",
          "bodyIntro": "The recording session was the moment all the song’s creators – the composer, songwriter, and singer – finally came together. The dream team.",
          "subtitle": "<span>PVRIS</span> PREPPING IN THE STUDIO"
        }
      },
      {
        "page1": {
          "title": "ACT 3: THE FIRE RAGES",
          "subtitle": "SHOWMAKER <br /><span>// WORLDS</span>",
          "body": "Just as Showmaker defeats Chovy, the countdown clock hits zero. The moment they’ve been training for has come, in the form of a portal straight to Worlds. Time to make the future.",
          "breadcrumb": "THE STORY"
        },
        "page2": {
          "body": "He makes Syndra’s looks his own, adapting her signature headpiece with his own unique style and flair. He has full access to her moves and abilities, which he unleashes with a calculated, punishing fury.",
          "breadcrumb": "THE PRO",
          "trivia": "Showmaker channels Syndra, but doesn’t become her. Her trademark feminine look takes on new life when blended with his unique style.",
          "musicIntro": "Showmakerʼs fusion form sounds like",
          "triviaIntro": "Syndraʼs influence",
          "bodyIntro": "Standing at Worlds, surrounded by opponents from around the globe, Showmaker powers up to his final form. He channels Syndra fully, equipping her like a magical armor over his own skin.",
          "subtitle": "<span>SHOWMAKER</span> HITS HIS FINAL FORM"
        },
        "page3": {
          "subtitle": "<span>UNLEASHED POWER</span> AT WORLDS",
          "bodyIntro": "Showmaker pulls off transcendent moves in the final showdown. To kickstart the epic battle, he gathers up Syndra’s orbs and unleashes a massive Ult.",
          "body": "Maybe more impressive, though, is when he uses Force of Will to toss a car at Jackeylove. Blink and you might miss it.",
          "musicIntro": "Showmaker’s big move sounds like",
          "breadcrumb": "BIG MOVE"
        },
        "page4": {
          "body": "The team created a safe space to get PVRIS through her make or break moment. She brought the intensity by channeling the emotion of the lyrics.",
          "breadcrumb": "THE MUSIC",
          "trivia": "“My life feels like it’s in a constant state of reinvention & rising from the ashes. Destruction is essential for growth and change.”",
          "musicIntro": "Worlds Anthem 2021",
          "triviaIntro": "PVRIS’ Burn It All Down story",
          "bodyIntro": "PVRIS had to go all out, vocally, to deliver the scale and scope that a Worlds Anthem – and stadium-worthy track – demand.",
          "subtitle": "CAPTURING THE FINAL <span>RECORDING</span>"
        }
      }
    ],
    "pro": [
      {
        "body": "Showmaker’s relentless dedication and next level game sense make him a calculated monster on the Rift. Having brought the Summoner’s Cup back to Korea, he’s a clear leader of the LCK’s next generation.",
        "musicIntro": "Top of Showmaker’s playlist right now",
        "profile": {
          "name": "Name: <span>허수</span> / Heo Su",
          "country": "Country: South Korea",
          "team": "Team: DWG KIA",
          "role": "Role: Mid"
        },
        "profileProLabel": "Profile",
        "champs": [
          "zoe",
          "syndra",
          "leblanc",
          "akali"
        ],
        "subtitle": "SHOWMAKER",
        "title": "FEATURED PROS",
        "champsProLabel": "Most Played Champs"
      },
      {
        "body": "The king of do-or-die, all-in, fiery gameplay, Chovy takes no prisoners. He may not have a Championship to his name yet, but Chovy’s legacy is just beginning. And he’s aiming for the very top.",
        "musicIntro": "Top of Chovy’s playlist right now",
        "profile": {
          "name": "Name: Jeong Ji-hoon / 정지훈",
          "country": "Country: South Korea",
          "team": "Team: Hanwha Life Esports",
          "role": "Role: Mid"
        },
        "profileProLabel": "Profile",
        "champs": [
          "zoe",
          "sylas",
          "irelia",
          "orianna"
        ],
        "subtitle": "CHOVY",
        "title": "FEATURED PROS",
        "champsProLabel": "Most Played Champs"
      },
      {
        "body": "A rising talent unafraid to carry entire teams on his shoulders, Pyosik is known for macro ability and bold shotcalling. Even if his squad struggles, he’s only one good split away from stardom.",
        "musicIntro": "Top of Pyosik’s playlist right now",
        "profile": {
          "name": "Name: Hong Chang-hyeon / 홍창현",
          "country": "Country: South Korea",
          "team": "Team: DRX",
          "role": "Role: Jungle"
        },
        "profileProLabel": "Profile",
        "champs": [
          "leesin",
          "olaf",
          "nidalee",
          "lillia"
        ],
        "subtitle": "PYOSIK",
        "title": "FEATURED PROS",
        "champsProLabel": "Most Played Champs"
      },
      {
        "body": "The Unkillable Demon King – the prodigy who won a record-breaking number of titles and changed the game forever. Faker defined the first decade of LoL Esports. Now, his eyes are on the next decade.",
        "musicIntro": "Top of Faker’s playlist right now",
        "profile": {
          "name": "Name: Lee Sang-hyeok / 이상혁",
          "country": "Country: South Korea",
          "team": "Team: T1",
          "role": "Role: Mid"
        },
        "profileProLabel": "Profile",
        "champs": [
          "azir",
          "ryze",
          "orianna",
          "galio"
        ],
        "subtitle": "FAKER",
        "title": "FEATURED PROS",
        "champsProLabel": "Most Played Champs"
      },
      {
        "body": "The living embodiment of “jungle diff,” Canyon is a World Champion and Finals MVP known for his inventive builds. His cuddly persona earned him the name “Polar Bear” but he’s all teeth on the Rift.",
        "musicIntro": "Top of Canyon’s playlist right now",
        "profile": {
          "name": "Name: Kim Geon-bu / 김건부",
          "country": "Country: South Korea",
          "team": "Team: DWG KIA",
          "role": "Role: Jungler"
        },
        "profileProLabel": "Profile",
        "champs": [
          "leesin",
          "graves",
          "nidalee",
          "sejuani"
        ],
        "subtitle": "CANYON",
        "title": "FEATURED PROS",
        "champsProLabel": "Most Played Champs"
      }
    ],
    "champion": {
      "body": "Syndra, a ranged mage, is one of Showmakerʼs most-played champs. When he first got into League, he was a Syndra one-trick. Heʼs expanded his champ pool since, but the orb-slinging sorceress remains one of his most reliable (and most intimidating) picks.",
      "trivia": "“Syndraʼs ranged powers and terrifying spheres were the perfect weapons to keep a cross-city battle interesting. Showmaker quickly scatters the group from the start and keeps everyone on their toes.”",
      "downloadId": "SYNDRA",
      "musicIntro": "Syndraʼs theme is",
      "triviaIntro": "Designer’s notes",
      "tagline": "THE DARK SOVEREIGN",
      "fullProfileUrl": "https://www.google.com/",
      "subtitle": "SYNDRA",
      "title": "FEATURED CHAMPION",
      "fullProfileLabel": "See full profile"
    },
    "skill": {
      "title": "SKILL BUILDING",
      "subtitle": "SHOWMAKER STRATEGIZES",
      "body": "To be the best, you have to outhink the rest. Showmaker uses the LCK analysis lab to review his champion pool (which of course includes Akali and Zoe), breakdown gameplay, and assess the competition. This is where he sharpens his brilliant strategic mind, so he’s always one step ahead on the Rift.",
      "musicIntro": "Music to strategize to"
    },
    "easterEgg": {
      "title": "EASTER EGG",
      "subtitle": "SIX SUMMONERʼS CUPS",
      "body": "The LCK has won Worlds a record-setting six times. As an homage to this storied legacy of success, their analysis lab features six Summoner’s Cup holograms, suspended in the air. A clear reminder of why they train.",
      "teaser": "Other LCK Easter Eggs to look out for: a reference to Showmaker’s K-Pop fan & a nod to Caster Jun.",
      "musicIntro": "The 2017 Worlds Anthem"
    }
  }
};
