

import LoadingImg1 from 'assets/images/landing/loader-img-1.jpg';
import LoadingImg2 from 'assets/images/landing/loader-img-2.jpg';
import LoadingImg3 from 'assets/images/landing/loader-img-3.jpg';

import ClubhouseSeoulRoom0 from 'assets/images/clubhouses/seoul/seoul_room0.jpg';
import ClubhouseSeoulRoom1 from 'assets/images/clubhouses/seoul/seoul_room1.jpg';
import ClubhouseSeoulRoom2 from 'assets/images/clubhouses/seoul/seoul_room2.jpg';
import ClubhouseBerlinRoom0 from 'assets/images/clubhouses/berlin/berlin_room0.jpg';
import ClubhouseBerlinRoom1 from 'assets/images/clubhouses/berlin/berlin_room1.jpg';
import ClubhouseBerlinRoom2 from 'assets/images/clubhouses/berlin/berlin_room2.jpg';
import ClubhouseBerlinRoom3 from 'assets/images/clubhouses/berlin/berlin_room3.jpg';
import ClubhouseShanghaiRoom0 from 'assets/images/clubhouses/shanghai/shanghai_room0.jpg';
import ClubhouseShanghaiRoom1 from 'assets/images/clubhouses/shanghai/shanghai_room1.jpg';
import ClubhouseShanghaiRoom2 from 'assets/images/clubhouses/shanghai/shanghai_room2.jpg';

import ClubhouseTitleSeoulEN from 'assets/lottie/title_seoul_en.json';
import ClubhouseTitleSeoulKO from 'assets/lottie/title_seoul_ko.json';
import ClubhouseTitleSeoulES from 'assets/lottie/title_seoul_es.json';
import ClubhouseTitleBerlinEN from 'assets/lottie/title_berlin_en.json';
import ClubhouseTitleBerlinKO from 'assets/lottie/title_berlin_ko.json';
import ClubhouseTitleBerlinES from 'assets/lottie/title_berlin_es.json';
import ClubhouseTitleShanghaiEN from 'assets/lottie/title_shanghai_en.json';
import ClubhouseTitleShanghaiKO from 'assets/lottie/title_shanghai_ko.json';
import ClubhouseTitleShanghaiES from 'assets/lottie/title_shanghai_es.json';

import ClubhouseTitleSeoulENMobile from 'assets/lottie/title_seoul_en_mobile.json';
import ClubhouseTitleSeoulKOMobile from 'assets/lottie/title_seoul_ko_mobile.json';
import ClubhouseTitleSeoulESMobile from 'assets/lottie/title_seoul_es_mobile.json';
import ClubhouseTitleBerlinENMobile from 'assets/lottie/title_berlin_en_mobile.json';
import ClubhouseTitleBerlinKOMobile from 'assets/lottie/title_berlin_ko_mobile.json';
import ClubhouseTitleBerlinESMobile from 'assets/lottie/title_berlin_es_mobile.json';
import ClubhouseTitleShanghaiENMobile from 'assets/lottie/title_shanghai_en_mobile.json';
import ClubhouseTitleShanghaiKOMobile from 'assets/lottie/title_shanghai_ko_mobile.json';
import ClubhouseTitleShanghaiESMobile from 'assets/lottie/title_shanghai_es_mobile.json';
import SpotifyPresentsEN from 'assets/lottie/spotify_presents_en.json';
import SpotifyPresentsES from 'assets/lottie/spotify_presents_es.json';
import SpotifyPresentsKO from 'assets/lottie/spotify_presents_ko.json';
import UncoverEN from 'assets/lottie/uncover_en.json';
import UncoverES from 'assets/lottie/uncover_es.json';
import UncoverKO from 'assets/lottie/uncover_ko.json';

// Seoul Hotspot Overlay assets
import SeoulSkill from 'assets/images/clubhouses/seoul/seoul_skill.jpg';
import SeoulEasterEgg from 'assets/images/clubhouses/seoul/seoul_easter_egg.jpg';
import SeoulStorytellingAct1Page1 from 'assets/images/clubhouses/seoul/seoul_storytelling_1_1.jpg';
import SeoulStorytellingAct1Page2 from 'assets/images/clubhouses/seoul/seoul_storytelling_1_2.jpg';
import SeoulStorytellingAct1Page3 from 'assets/images/clubhouses/seoul/seoul_storytelling_1_3.jpg';
import SeoulStorytellingAct1Page4 from 'assets/images/clubhouses/seoul/seoul_storytelling_1_4.jpg';
import SeoulStorytellingAct2Page1 from 'assets/images/clubhouses/seoul/seoul_storytelling_2_1.jpg';
import SeoulStorytellingAct2Page2 from 'assets/images/clubhouses/seoul/seoul_storytelling_2_2.jpg';
import SeoulStorytellingAct2Page3 from 'assets/images/clubhouses/seoul/seoul_storytelling_2_3.jpg';
import SeoulStorytellingAct2Page4 from 'assets/images/clubhouses/seoul/seoul_storytelling_2_4.jpg';
import SeoulStorytellingAct3Page1 from 'assets/images/clubhouses/seoul/seoul_storytelling_3_1.jpg';
import SeoulStorytellingAct3Page2 from 'assets/images/clubhouses/seoul/seoul_storytelling_3_2.jpg';
import SeoulStorytellingAct3Page3 from 'assets/images/clubhouses/seoul/seoul_storytelling_3_3.jpg';
import SeoulStorytellingAct3Page4 from 'assets/images/clubhouses/seoul/seoul_storytelling_3_4.jpg';

//Seoul Pro assets
import SeoulProsCanyonDefault from 'assets/images/clubhouses/seoul/seoul_pros_canyon_default.png';
import SeoulProsFakerDefault from 'assets/images/clubhouses/seoul/seoul_pros_faker_default.png';
import SeoulProsChovyDefault from 'assets/images/clubhouses/seoul/seoul_pros_chovy_default.png';
import SeoulProsShowmakerDefault from 'assets/images/clubhouses/seoul/seoul_pros_showmaker_default.png';
import SeoulProsPyosikDefault from 'assets/images/clubhouses/seoul/seoul_pros_pyosik_default.png';
import SeoulProsCanyonHold from 'assets/images/clubhouses/seoul/seoul_pros_canyon_hold.png';
import SeoulProsFakerHold from 'assets/images/clubhouses/seoul/seoul_pros_faker_hold.png';
import SeoulProsChovyHold from 'assets/images/clubhouses/seoul/seoul_pros_chovy_hold.png';
import SeoulProsShowmakerHold from 'assets/images/clubhouses/seoul/seoul_pros_showmaker_hold.png';
import SeoulProsPyosikHold from 'assets/images/clubhouses/seoul/seoul_pros_pyosik_hold.png';
import SeoulProsShowmakerDownload from 'assets/images/downloadables/seoul/seoul_pros_showmaker_download.jpg';

// Berlin pro assets
import BerlinProsArmutDefault from 'assets/images/clubhouses/berlin/berlin_pros_armut_default.png';
import BerlinProsCarzzyDefault from 'assets/images/clubhouses/berlin/berlin_pros_carzzy_default.png';
import BerlinProsHansSamaDefault from 'assets/images/clubhouses/berlin/berlin_pros_hans_sama_default.png';
import BerlinProsRekklesDefault from 'assets/images/clubhouses/berlin/berlin_pros_rekkles_default.png';
import BerlinProsWunderDefault from 'assets/images/clubhouses/berlin/berlin_pros_wunder_default.png';
import BerlinProsArmutHold from 'assets/images/clubhouses/berlin/berlin_pros_armut_hold.png';
import BerlinProsCarzzyHold from 'assets/images/clubhouses/berlin/berlin_pros_carzzy_hold.png';
import BerlinProsHansSamaHold from 'assets/images/clubhouses/berlin/berlin_pros_hans_sama_hold.png';
import BerlinProsRekklesHold from 'assets/images/clubhouses/berlin/berlin_pros_rekkles_hold.png';
import BerlinProsWunderHold from 'assets/images/clubhouses/berlin/berlin_pros_wunder_hold.png';
import BerlinProsRekklesDownload from 'assets/images/downloadables/berlin/berlin_pros_rekkles_download.jpg';

// Berlin storytelling
import BerlinSkill from 'assets/images/clubhouses/berlin/berlin_skill.jpg';
import BerlinEasterEgg from 'assets/images/clubhouses/berlin/berlin_easter_egg.jpg';
import BerlinStorytellingAct1Page1 from 'assets/images/clubhouses/berlin/berlin_storytelling_1_1.jpg';
import BerlinStorytellingAct1Page2 from 'assets/images/clubhouses/berlin/berlin_storytelling_1_2.jpg';
import BerlinStorytellingAct1Page3 from 'assets/images/clubhouses/berlin/berlin_storytelling_1_3.jpg';
import BerlinStorytellingAct1Page4 from 'assets/images/clubhouses/berlin/berlin_storytelling_1_4.jpg';
import BerlinStorytellingAct2Page1 from 'assets/images/clubhouses/berlin/berlin_storytelling_2_1.jpg';
import BerlinStorytellingAct2Page2 from 'assets/images/clubhouses/berlin/berlin_storytelling_2_2.jpg';
import BerlinStorytellingAct2Page3 from 'assets/images/clubhouses/berlin/berlin_storytelling_2_3.jpg';
import BerlinStorytellingAct2Page4 from 'assets/images/clubhouses/berlin/berlin_storytelling_2_4.jpg';
import BerlinStorytellingAct3Page1 from 'assets/images/clubhouses/berlin/berlin_storytelling_3_1.jpg';
import BerlinStorytellingAct3Page2 from 'assets/images/clubhouses/berlin/berlin_storytelling_3_2.jpg';
import BerlinStorytellingAct3Page3 from 'assets/images/clubhouses/berlin/berlin_storytelling_3_3.jpg';
import BerlinStorytellingAct3Page4 from 'assets/images/clubhouses/berlin/berlin_storytelling_3_4.jpg';

// Shanghai pro assets
import ShanghaiProsBinDefault from 'assets/images/clubhouses/shanghai/shanghai_pros_bin_default.png';
import ShanghaiProsJackyloveDefault from 'assets/images/clubhouses/shanghai/shanghai_pros_jackylove_default.png';
import ShanghaiProsLwxDefault from 'assets/images/clubhouses/shanghai/shanghai_pros_lwx_default.png';
import ShanghaiProsRookieDefault from 'assets/images/clubhouses/shanghai/shanghai_pros_rookie_default.png';
import ShanghaiProsTheshyDefault from 'assets/images/clubhouses/shanghai/shanghai_pros_theshy_default.png';
import ShanghaiProsBinHold from 'assets/images/clubhouses/shanghai/shanghai_pros_bin_hold.png';
import ShanghaiProsJackyloveHold from 'assets/images/clubhouses/shanghai/shanghai_pros_jackylove_hold.png';
import ShanghaiProsLwxHold from 'assets/images/clubhouses/shanghai/shanghai_pros_lwx_hold.png';
import ShanghaiProsRookieHold from 'assets/images/clubhouses/shanghai/shanghai_pros_rookie_hold.png';
import ShanghaiProsTheshyHold from 'assets/images/clubhouses/shanghai/shanghai_pros_theshy_hold.png';
import ShanghaiProsJackyloveDownload from 'assets/images/downloadables/shanghai/shanghai_pros_jackylove_download.jpg';

// Shanghai Storytelling
import ShanghaiSkill from 'assets/images/clubhouses/shanghai/shanghai_skill.jpg';
import ShanghaiEasterEgg from 'assets/images/clubhouses/shanghai/shanghai_easter_egg.jpg';
import ShanghaiStorytellingAct1Page1 from 'assets/images/clubhouses/shanghai/shanghai_storytelling_1_1.jpg';
import ShanghaiStorytellingAct1Page2 from 'assets/images/clubhouses/shanghai/shanghai_storytelling_1_2.jpg';
import ShanghaiStorytellingAct1Page3 from 'assets/images/clubhouses/shanghai/shanghai_storytelling_1_3.jpg';
import ShanghaiStorytellingAct1Page4 from 'assets/images/clubhouses/shanghai/shanghai_storytelling_1_4.jpg';
import ShanghaiStorytellingAct2Page1 from 'assets/images/clubhouses/shanghai/shanghai_storytelling_2_1.jpg';
import ShanghaiStorytellingAct2Page2 from 'assets/images/clubhouses/shanghai/shanghai_storytelling_2_2.jpg';
import ShanghaiStorytellingAct2Page3 from 'assets/images/clubhouses/shanghai/shanghai_storytelling_2_3.jpg';
import ShanghaiStorytellingAct2Page4 from 'assets/images/clubhouses/shanghai/shanghai_storytelling_2_4.jpg';
import ShanghaiStorytellingAct3Page1 from 'assets/images/clubhouses/shanghai/shanghai_storytelling_3_1.jpg';
import ShanghaiStorytellingAct3Page2 from 'assets/images/clubhouses/shanghai/shanghai_storytelling_3_2.jpg';
import ShanghaiStorytellingAct3Page3 from 'assets/images/clubhouses/shanghai/shanghai_storytelling_3_3.jpg';
import ShanghaiStorytellingAct3Page4 from 'assets/images/clubhouses/shanghai/shanghai_storytelling_3_4.jpg';

//Champ assets
import ChampLCK from 'assets/images/clubhouses/seoul/seoul_champ_syndra.jpg';
import ChampLEC from 'assets/images/clubhouses/berlin/berlin_champ_jhin.jpg';
import ChampLPL from 'assets/images/clubhouses/shanghai/shanghai_champ_kaisa.jpg';
import ChampLCKDownload from 'assets/images/downloadables/seoul/seoul_champ_syndra_download.jpg';
import ChampLECDownload from 'assets/images/downloadables/berlin/berlin_champ_jhin_download.jpg';
import ChampLPLDownload from 'assets/images/downloadables/shanghai/shanghai_champ_kaisa_download.jpg';

// Champs icon
import Aatrox from 'assets/images/ui/champs_aatrox.jpg';
import Akali from 'assets/images/ui/champs_akali.jpg';
import Aphelios from 'assets/images/ui/champs_aphelios.jpg';
import Ashe from 'assets/images/ui/champs_ashe.jpg';
import Azir from 'assets/images/ui/champs_azir.jpg';
import Camille from 'assets/images/ui/champs_camille.jpg';
import Ezreal from 'assets/images/ui/champs_ezreal.jpg';
import Galio from 'assets/images/ui/champs_galio.jpg';
import Gangplank from 'assets/images/ui/champs_gangplank.jpg';
import Gnar from 'assets/images/ui/champs_gnar.jpg';
import Graves from 'assets/images/ui/champs_graves.jpg';
import Irelia from 'assets/images/ui/champs_irelia.jpg';
import Jayce from 'assets/images/ui/champs_jayce.jpg';
import Kaisa from 'assets/images/ui/champs_kaisa.jpg';
import Kalista from 'assets/images/ui/champs_kalista.jpg';
import Kennen from 'assets/images/ui/champs_kennen.jpg';
import Leblanc from 'assets/images/ui/champs_leblanc.jpg';
import Leesin from 'assets/images/ui/champs_leesin.jpg';
import Lillia from 'assets/images/ui/champs_lillia.jpg';
import Lucian from 'assets/images/ui/champs_lucian.jpg';
import Nidalee from 'assets/images/ui/champs_nidalee.jpg';
import Olaf from 'assets/images/ui/champs_olaf.jpg';
import Orianna from 'assets/images/ui/champs_orianna.jpg';
import Ornn from 'assets/images/ui/champs_ornn.jpg';
import Renekton from 'assets/images/ui/champs_renekton.jpg';
import Ryze from 'assets/images/ui/champs_ryze.jpg';
import Sejuani from 'assets/images/ui/champs_sejuani.jpg';
import Sivir from 'assets/images/ui/champs_sivir.jpg';
import Sylas from 'assets/images/ui/champs_sylas.jpg';
import Syndra from 'assets/images/ui/champs_syndra.jpg';
import Tristana from 'assets/images/ui/champs_tristana.jpg';
import Varus from 'assets/images/ui/champs_varus.jpg';
import Wukong from 'assets/images/ui/champs_wukong.jpg';
import Xayah from 'assets/images/ui/champs_xayah.jpg';
import Zoe from 'assets/images/ui/champs_zoe.jpg';

/**
 * Clubhouse Selector Images
 */
import SeoulSeq0 from 'assets/images/landing/seoul-seq-0.jpg';
import SeoulSeq1 from 'assets/images/landing/seoul-seq-1.jpg';
import SeoulSeq2 from 'assets/images/landing/seoul-seq-2.jpg';
import SeoulSeq3 from 'assets/images/landing/seoul-seq-3.jpg';
import SeoulSeq4 from 'assets/images/landing/seoul-seq-4.jpg';
import SeoulSeq5 from 'assets/images/landing/seoul-seq-5.jpg';
import SeoulSeq6 from 'assets/images/landing/seoul-seq-6.jpg';
import SeoulSeq7 from 'assets/images/landing/seoul-seq-7.jpg';
import SeoulSeq8 from 'assets/images/landing/seoul-seq-8.jpg';
import BerlinSeq0 from 'assets/images/landing/berlin-seq-0.jpg';
import BerlinSeq1 from 'assets/images/landing/berlin-seq-1.jpg';
import BerlinSeq2 from 'assets/images/landing/berlin-seq-2.jpg';
import BerlinSeq3 from 'assets/images/landing/berlin-seq-3.jpg';
import BerlinSeq4 from 'assets/images/landing/berlin-seq-4.jpg';
import BerlinSeq5 from 'assets/images/landing/berlin-seq-5.jpg';
import BerlinSeq6 from 'assets/images/landing/berlin-seq-6.jpg';
import BerlinSeq7 from 'assets/images/landing/berlin-seq-7.jpg';
import BerlinSeq8 from 'assets/images/landing/berlin-seq-8.jpg';
import ShanghaiSeq0 from 'assets/images/landing/shanghai-seq-0.jpg';
import ShanghaiSeq1 from 'assets/images/landing/shanghai-seq-1.jpg';
import ShanghaiSeq2 from 'assets/images/landing/shanghai-seq-2.jpg';
import ShanghaiSeq3 from 'assets/images/landing/shanghai-seq-3.jpg';
import ShanghaiSeq4 from 'assets/images/landing/shanghai-seq-4.jpg';
import ShanghaiSeq5 from 'assets/images/landing/shanghai-seq-5.jpg';
import ShanghaiSeq6 from 'assets/images/landing/shanghai-seq-6.jpg';
import ShanghaiSeq7 from 'assets/images/landing/shanghai-seq-7.jpg';
import ShanghaiSeq8 from 'assets/images/landing/shanghai-seq-8.jpg';

import { HotspotsTrackInfo, TrackInfo } from 'store/playlists.types';
import { isStorybook } from 'u9/utils/platform';
import { ClubhousePageProps } from 'containers/ClubhousePage/ClubhousePage';
import { HotspotTypes } from 'store/clubhouse.types';
import { StorytellingOverlay } from 'store/copy.types';

import { LOCALES } from './config';

const videoBaseUrl = isStorybook()
  ? 'https://spotify-league-of-legends-d533-uploads-cdn.unit9.net'
  : `${process.env.CDN_URL}`;

type StorytellingAsset = Record<
  ClubhousePageProps['city'],
  Record<
    HotspotTypes.storytelling1 | HotspotTypes.storytelling2 | HotspotTypes.storytelling3,
    Partial<Record<keyof StorytellingOverlay, string>>
  >
>;

export const STORYTELLING_IMAGES: StorytellingAsset = {
  seoul: {
    storytelling1: {
      page1: SeoulStorytellingAct1Page1,
      page2: SeoulStorytellingAct1Page2,
      page3: SeoulStorytellingAct1Page3,
      page4: SeoulStorytellingAct1Page4,
    },
    storytelling2: {
      page1: SeoulStorytellingAct2Page1,
      page2: SeoulStorytellingAct2Page2,
      page3: SeoulStorytellingAct2Page3,
      page4: SeoulStorytellingAct2Page4,
    },
    storytelling3: {
      page1: SeoulStorytellingAct3Page1,
      page2: SeoulStorytellingAct3Page2,
      page3: SeoulStorytellingAct3Page3,
      page4: SeoulStorytellingAct3Page4,
    }
  },
  berlin: {
    storytelling1: {
      page1: BerlinStorytellingAct1Page1,
      page2: BerlinStorytellingAct1Page2,
      page3: BerlinStorytellingAct1Page3,
      page4: BerlinStorytellingAct1Page4,
    },
    storytelling2: {
      page1: BerlinStorytellingAct2Page1,
      page2: BerlinStorytellingAct2Page2,
      page3: BerlinStorytellingAct2Page3,
      page4: BerlinStorytellingAct2Page4,
    },
    storytelling3: {
      page1: BerlinStorytellingAct3Page1,
      page2: BerlinStorytellingAct3Page2,
      page3: BerlinStorytellingAct3Page3,
      page4: BerlinStorytellingAct3Page4,
    }
  },
  shanghai: {
    storytelling1: {
      page1: ShanghaiStorytellingAct1Page1,
      page2: ShanghaiStorytellingAct1Page2,
      page3: ShanghaiStorytellingAct1Page3,
      page4: ShanghaiStorytellingAct1Page4,
    },
    storytelling2: {
      page1: ShanghaiStorytellingAct2Page1,
      page2: ShanghaiStorytellingAct2Page2,
      page3: ShanghaiStorytellingAct2Page3,
      page4: ShanghaiStorytellingAct2Page4,
    },
    storytelling3: {
      page1: ShanghaiStorytellingAct3Page1,
      page2: ShanghaiStorytellingAct3Page2,
      page3: ShanghaiStorytellingAct3Page3,
      page4: ShanghaiStorytellingAct3Page4,
    }
  }
};

export const STORYTELLING_VIDEOS: StorytellingAsset = {
  seoul: {
    storytelling1: {
      page2: `${videoBaseUrl}/videos/clubhouses/seoul_storytelling1_page2.mp4`,
      page3: `${videoBaseUrl}/videos/clubhouses/seoul_storytelling1_page3.mp4`,
      page4: `${videoBaseUrl}/videos/clubhouses/seoul_storytelling1_page4.mp4`
    },
    storytelling2: {
      page2: `${videoBaseUrl}/videos/clubhouses/seoul_storytelling2_page2.mp4`,
      page3: `${videoBaseUrl}/videos/clubhouses/seoul_storytelling2_page3.mp4`,
      page4: `${videoBaseUrl}/videos/clubhouses/seoul_storytelling2_page4.mp4`
    },
    storytelling3: {
      page2: `${videoBaseUrl}/videos/clubhouses/seoul_storytelling3_page2.mp4`,
      page3: `${videoBaseUrl}/videos/clubhouses/seoul_storytelling3_page3.mp4`,
      page4: `${videoBaseUrl}/videos/clubhouses/seoul_storytelling3_page4.mp4`
    }
  },
  berlin: {
    storytelling1: {
      page2: `${videoBaseUrl}/videos/clubhouses/berlin_storytelling1_page2.mp4`,
      page3: `${videoBaseUrl}/videos/clubhouses/berlin_storytelling1_page3.mp4`,
      page4: `${videoBaseUrl}/videos/clubhouses/berlin_storytelling1_page4.mp4`
    },
    storytelling2: {
      page2: `${videoBaseUrl}/videos/clubhouses/berlin_storytelling2_page2.mp4`,
      page3: `${videoBaseUrl}/videos/clubhouses/berlin_storytelling2_page3.mp4`,
      page4: `${videoBaseUrl}/videos/clubhouses/berlin_storytelling2_page4.mp4`
    },
    storytelling3: {
      page2: `${videoBaseUrl}/videos/clubhouses/berlin_storytelling3_page2.mp4`,
      page3: `${videoBaseUrl}/videos/clubhouses/berlin_storytelling3_page3.mp4`,
      page4: `${videoBaseUrl}/videos/clubhouses/berlin_storytelling3_page4.mp4`
    }
  },
  shanghai: {
    storytelling1: {
      page2: `${videoBaseUrl}/videos/clubhouses/shanghai_storytelling1_page2.mp4`,
      page3: `${videoBaseUrl}/videos/clubhouses/shanghai_storytelling1_page3.mp4`,
      page4: `${videoBaseUrl}/videos/clubhouses/shanghai_storytelling1_page4.mp4`
    },
    storytelling2: {
      page2: `${videoBaseUrl}/videos/clubhouses/shanghai_storytelling2_page2.mp4`,
      page3: `${videoBaseUrl}/videos/clubhouses/shanghai_storytelling2_page3.mp4`,
      page4: `${videoBaseUrl}/videos/clubhouses/shanghai_storytelling2_page4.mp4`
    },
    storytelling3: {
      page2: `${videoBaseUrl}/videos/clubhouses/shanghai_storytelling3_page2.mp4`,
      page3: `${videoBaseUrl}/videos/clubhouses/shanghai_storytelling3_page3.mp4`,
      page4: `${videoBaseUrl}/videos/clubhouses/shanghai_storytelling3_page4.mp4`
    }
  }
}

export const PRO_IMAGES: Record<
  ClubhousePageProps['city'],
  Record<
    string,
    Record<'main' | 'revealed', string>
  >
> = {
  seoul: {
    showmaker: {
      main: SeoulProsShowmakerDefault,
      revealed: SeoulProsShowmakerHold
    },
    chovy: {
      main: SeoulProsChovyDefault,
      revealed: SeoulProsChovyHold
    },
    pyosik: {
      main: SeoulProsPyosikDefault,
      revealed: SeoulProsPyosikHold
    },
    faker: {
      main: SeoulProsFakerDefault,
      revealed: SeoulProsFakerHold
    },
    canyon: {
      main: SeoulProsCanyonDefault,
      revealed: SeoulProsCanyonHold
    },
  },
  berlin: {
    rekkles: {
      main: BerlinProsRekklesDefault,
      revealed: BerlinProsRekklesHold
    },
    armut: {
      main: BerlinProsArmutDefault,
      revealed: BerlinProsArmutHold
    },
    hansSama: {
      main: BerlinProsHansSamaDefault,
      revealed: BerlinProsHansSamaHold
    },
    wunder: {
      main: BerlinProsWunderDefault,
      revealed: BerlinProsWunderHold
    },
    carzzy: {
      main: BerlinProsCarzzyDefault,
      revealed: BerlinProsCarzzyHold
    }
  },
  shanghai: {
    jackeylove: {
      main: ShanghaiProsJackyloveDefault,
      revealed: ShanghaiProsJackyloveHold
    },
    bin: {
      main: ShanghaiProsBinDefault,
      revealed: ShanghaiProsBinHold
    },
    lwx: {
      main: ShanghaiProsLwxDefault,
      revealed: ShanghaiProsLwxHold
    },
    rookie: {
      main: ShanghaiProsRookieDefault,
      revealed: ShanghaiProsRookieHold
    },
    theshy: {
      main: ShanghaiProsTheshyDefault,
      revealed: ShanghaiProsTheshyHold
    }
  }
}

export const CHAMP_THUMBNAILS = {
  aatrox: Aatrox,
  akali: Akali,
  aphelios: Aphelios,
  ashe: Ashe,
  azir: Azir,
  camille: Camille,
  ezreal: Ezreal,
  galio: Galio,
  gangplank: Gangplank,
  gnar: Gnar,
  graves: Graves,
  irelia: Irelia,
  jayce: Jayce,
  kaisa: Kaisa,
  kalista: Kalista,
  kennen: Kennen,
  leblanc: Leblanc,
  leesin: Leesin,
  lillia: Lillia,
  lucian: Lucian,
  nidalee: Nidalee,
  olaf: Olaf,
  orianna: Orianna,
  ornn: Ornn,
  renekton: Renekton,
  ryze: Ryze,
  sejuani: Sejuani,
  sivir: Sivir,
  sylas: Sylas,
  syndra: Syndra,
  tristana: Tristana,
  varus: Varus,
  wukong: Wukong,
  xayah: Xayah,
  zoe: Zoe
} as const;

export const CHAMP_IMAGES: Record<ClubhousePageProps['city'], string> = {
  seoul: ChampLCK,
  berlin: ChampLEC,
  shanghai: ChampLPL
}

export const SKILL_IMAGES: Record<ClubhousePageProps['city'], string> = {
  seoul: SeoulSkill,
  berlin: BerlinSkill,
  shanghai: ShanghaiSkill
}

export interface ImageConfig {
  top: string;
  left: string;
  scale: number;
  src: string;
}

export const EASTER_EGG_IMAGES: Record<
  ClubhousePageProps['city'],
  ImageConfig[]
> = {
  seoul: [
    {
      top: '-300rem',
      left: '70%',
      scale: 1.5,
      src: SeoulEasterEgg
    },
    {
      top: '300rem',
      left: '-50rem',
      scale: 1.5,
      src: SeoulEasterEgg
    },
    {
      top: '70%',
      left: '55%',
      scale: 1.5,
      src: SeoulEasterEgg
    }
  ],
  berlin: [
    {
      top: '-300rem',
      left: '70%',
      scale: 1.5,
      src: BerlinEasterEgg
    },
    {
      top: '300rem',
      left: '-50rem',
      scale: 1.5,
      src: BerlinEasterEgg
    },
    {
      top: '70%',
      left: '55%',
      scale: 1.5,
      src: BerlinEasterEgg
    }
  ],
  shanghai: [
    {
      top: '-300rem',
      left: '70%',
      scale: 1.5,
      src: ShanghaiEasterEgg
    },
    {
      top: '300rem',
      left: '-50rem',
      scale: 1.5,
      src: ShanghaiEasterEgg
    },
    {
      top: '70%',
      left: '55%',
      scale: 1.5,
      src: ShanghaiEasterEgg
    }
  ]
}

export const DOWNLOADABLES = {
  pro: {
    showmaker: SeoulProsShowmakerDownload,
    rekkles: BerlinProsRekklesDownload,
    jackeylove: ShanghaiProsJackyloveDownload
  },
  champion: {
    syndra: ChampLCKDownload,
    jhin: ChampLECDownload,
    kaisa: ChampLPLDownload,
  }
}

export const LANDING_IMAGES: ImageConfig[] = [
  {
    top: '-140rem',
    left: '60%',
    scale: 1.8,
    src: LoadingImg1
  },
  {
    top: '85%',
    left: '75%',
    scale: 1.4,
    src: LoadingImg2
  },
  {
    top: '40%',
    left: '-100rem',
    scale: 2.2,
    src: LoadingImg3
  }
];

export const CLUBHOUSE_BACKGROUND_IMAGES: Record<
  ClubhousePageProps['city'],
  {
    image: string;
    mobileStartingHorizontalOffset: number;
    mobileStartingVerticalOffset: number
  }[]
> = {
  seoul: [
    {
      image: ClubhouseSeoulRoom0,
      mobileStartingHorizontalOffset: -335,
      mobileStartingVerticalOffset: -75
    },
    {
      image: ClubhouseSeoulRoom1,
      mobileStartingHorizontalOffset: -200,
      mobileStartingVerticalOffset: -30
    },
    {
      image: ClubhouseSeoulRoom2,
      mobileStartingHorizontalOffset: 39,
      mobileStartingVerticalOffset: 40
    }
  ],
  berlin: [
    {
      image: ClubhouseBerlinRoom0,
      mobileStartingHorizontalOffset: 60,
      mobileStartingVerticalOffset: 30
    },
    {
      image: ClubhouseBerlinRoom1,
      mobileStartingHorizontalOffset: 50,
      mobileStartingVerticalOffset: 0
    },
    {
      image: ClubhouseBerlinRoom2,
      mobileStartingHorizontalOffset: 80,
      mobileStartingVerticalOffset: 0
    },
    {
      image: ClubhouseBerlinRoom3,
      mobileStartingHorizontalOffset: -200,
      mobileStartingVerticalOffset: 0
    }
  ],
  shanghai: [
    {
      image: ClubhouseShanghaiRoom0,
      mobileStartingHorizontalOffset: 290,
      mobileStartingVerticalOffset: -50
    },
    {
      image: ClubhouseShanghaiRoom1,
      mobileStartingHorizontalOffset: 160,
      mobileStartingVerticalOffset: 75
    },
    {
      image: ClubhouseShanghaiRoom2,
      mobileStartingHorizontalOffset: 0,
      mobileStartingVerticalOffset: 0
    }
  ]
};

export const LOTTIE_FILES: Record<
  typeof LOCALES[number],
  {
    landing: {
      clubhouseTitle: Record<'desktop' | 'mobile', Record<ClubhousePageProps['city'], any>>;
      spotifyPresents: any;
      uncover: any;
    }
  }
> = {
  en: {
    landing: {
      clubhouseTitle: {
        desktop: {
          seoul: ClubhouseTitleSeoulEN,
          berlin: ClubhouseTitleBerlinEN,
          shanghai: ClubhouseTitleShanghaiEN
        },
        mobile: {
          seoul: ClubhouseTitleSeoulENMobile,
          berlin: ClubhouseTitleBerlinENMobile,
          shanghai: ClubhouseTitleShanghaiENMobile
        }
      },
      spotifyPresents: SpotifyPresentsEN,
      uncover: UncoverEN
    },
  },
  es: {
    landing: {
      clubhouseTitle: {
        desktop: {
          seoul: ClubhouseTitleSeoulES,
          berlin: ClubhouseTitleBerlinES,
          shanghai: ClubhouseTitleShanghaiES
        },
        mobile: {
          seoul: ClubhouseTitleSeoulESMobile,
          berlin: ClubhouseTitleBerlinESMobile,
          shanghai: ClubhouseTitleShanghaiESMobile
        }
      },
      spotifyPresents: SpotifyPresentsES,
      uncover: UncoverES
    }
  },
  ko: {
    landing: {
      clubhouseTitle: {
        desktop: {
          seoul: ClubhouseTitleSeoulKO,
          berlin: ClubhouseTitleBerlinKO,
          shanghai: ClubhouseTitleShanghaiKO
        },
        mobile: {
          seoul: ClubhouseTitleSeoulKOMobile,
          berlin: ClubhouseTitleBerlinKOMobile,
          shanghai: ClubhouseTitleShanghaiKOMobile
        }
      },
      spotifyPresents: SpotifyPresentsKO,
      uncover: UncoverKO
    }
  }
};

export const CLUBHOUSE_SELECTOR_IMAGES: Record<
  ClubhousePageProps['city'],
  { main: string; sequence: string[] }
> = {
  seoul: {
    main: SeoulSeq0,
    sequence: [
      SeoulSeq1, SeoulSeq2, SeoulSeq3, SeoulSeq4,
      SeoulSeq5, SeoulSeq6, SeoulSeq7, SeoulSeq8,
    ]
  },
  berlin: {
    main: BerlinSeq0,
    sequence: [
      BerlinSeq1, BerlinSeq2, BerlinSeq3, BerlinSeq4,
      BerlinSeq5, BerlinSeq6, BerlinSeq7, BerlinSeq8,
    ]
  },
  shanghai: {
    main: ShanghaiSeq0,
    sequence: [
      ShanghaiSeq1, ShanghaiSeq2, ShanghaiSeq3, ShanghaiSeq4,
      ShanghaiSeq5, ShanghaiSeq6, ShanghaiSeq7, ShanghaiSeq8,
    ]
  }
};

export const ANTHEM_VIDEO: Record<'manifestUrl' | 'mp4Url', string> = {
  manifestUrl: `${videoBaseUrl}/videos/anthem/hls/anthem.m3u8`,
  mp4Url: `${videoBaseUrl}/videos/anthem/mp4/anthem.mp4`
};

export const ANTHEM_TRACK: TrackInfo = {
  id: '0ked784BOZ1JtRVHux98jE',
  previewUrl: 'https://p.scdn.co/mp3-preview/c00c48d6ab9694c68e19945f3022869d30744520?cid=774b29d4f13844c495f206cafdad9c86'
};

export const HOTSPOTS_TRACK_INFO: HotspotsTrackInfo = {
  seoul: {
    storytelling: [
      {
        page1: null,
        page2: { id: '0rZXS2o16WEtWP1UeifGVD', previewUrl: 'https://p.scdn.co/mp3-preview/dd5720210b00c934047a979e29a5cabd25d077d6?cid=774b29d4f13844c495f206cafdad9c86' },
        page3: { id: '0InmjH0I3saR87wmlYg1fG', previewUrl: 'https://p.scdn.co/mp3-preview/01ed1735b674e0dfc06903ebf21631696ee90cec?cid=774b29d4f13844c495f206cafdad9c86' },
        page4: { id: '6RwiFA2a6b8xICdSi4wUqk', previewUrl: 'https://p.scdn.co/mp3-preview/cfbacb69b8b7271ee2d8753fab1e41fa95ff0506?cid=774b29d4f13844c495f206cafdad9c86' }
      },
      {
        page1: null,
        page2: { id: '3bidbhpOYeV4knp8AIu8Xn', previewUrl: 'https://p.scdn.co/mp3-preview/1f826380a449ec9a8bae45967d908fffd7ff5222?cid=774b29d4f13844c495f206cafdad9c86' },
        page3: { id: '53YZi9zgTnF0Py0K6ejyWz', previewUrl: 'https://p.scdn.co/mp3-preview/d3decbd1491764515f15fa6e1690b25dcb97312b?cid=774b29d4f13844c495f206cafdad9c86' },
        page4: ANTHEM_TRACK
      },
      {
        page1: null,
        page2: { id: '5uHYcK0nbEYgRaFTY5BqnP', previewUrl: 'https://p.scdn.co/mp3-preview/9889da6594e61f4266fbb9be6cc15c86431dd374?cid=774b29d4f13844c495f206cafdad9c86' },
        page3: { id: '5qzujs8h10mboHGd3GYAcY', previewUrl: 'https://p.scdn.co/mp3-preview/07b539ce942bb154196744e6c4f1c50e64066410?cid=774b29d4f13844c495f206cafdad9c86' },
        page4: ANTHEM_TRACK
      }
    ],
    pro: [
      { id: '2m8wayjD04EOjLtJqWC4oQ', previewUrl: 'https://p.scdn.co/mp3-preview/bb4f497330ea507cb340c05adacd1c62ff5ad4fa?cid=774b29d4f13844c495f206cafdad9c86' },
      { id: '2SMq0lOqCTHayWa9juoI0d', previewUrl: 'https://p.scdn.co/mp3-preview/f2cdffb567a8d60691bba20776e446dbf7a4a69e?cid=774b29d4f13844c495f206cafdad9c86' },
      { id: '4YycgLIc3P6oQRY5NiOybl', previewUrl: 'https://p.scdn.co/mp3-preview/d85c1c91e769adcd5fd585e7bba39fdca76fabcd?cid=774b29d4f13844c495f206cafdad9c86' },
      { id: '5QwLHJDelqEuMEEOT4oLy9', previewUrl: 'https://p.scdn.co/mp3-preview/b9b65aae8bdcdc7ed18ea5151b5fc77d922ce208?cid=774b29d4f13844c495f206cafdad9c86' },
      { id: '0u66s3kGf70cWvKHWFMY2p', previewUrl: 'https://p.scdn.co/mp3-preview/a2a8bde526379eb419ecf08582ead0dbc7ea2f1e?cid=774b29d4f13844c495f206cafdad9c86' }
    ],
    champion: { id: '5qzujs8h10mboHGd3GYAcY', previewUrl: 'https://p.scdn.co/mp3-preview/07b539ce942bb154196744e6c4f1c50e64066410?cid=774b29d4f13844c495f206cafdad9c86' },
    skill: { id: '5ECySGXI4N8MCv6jf7HU9n', previewUrl: 'https://p.scdn.co/mp3-preview/91d5aa4acb2d642aa00e088d072747c666988cdf?cid=774b29d4f13844c495f206cafdad9c86' },
    easterEgg: { id: '1FpVJ7HpZInE2GvhVE2TwT', previewUrl: 'https://p.scdn.co/mp3-preview/2ada8eb10486d7ce23437d6825b280bd85896f66?cid=774b29d4f13844c495f206cafdad9c86' }
  },
  berlin: {
    storytelling: [
      {
        page1: null,
        page2: { id: '7fAzrCS4F29tUvXxi4PcRR', previewUrl: 'https://p.scdn.co/mp3-preview/ff629bb0b22dd2fc3d6fa8a57a13f889e5567f60?cid=774b29d4f13844c495f206cafdad9c86' },
        page3: { id: '7mYKxi4gqlSweFg2xigZ1w', previewUrl: 'https://p.scdn.co/mp3-preview/8054f5a5d20396e3a327bc3ba21eb951d7713172?cid=774b29d4f13844c495f206cafdad9c86' },
        page4: { id: '69Sy7207dnixZ6w7RSV9Kb', previewUrl: 'https://p.scdn.co/mp3-preview/2043a96c4388255fa8becf04d1583d32be6e34f0?cid=774b29d4f13844c495f206cafdad9c86' }
      },
      {
        page1: null,
        page2: { id: '576ZvKtbYJdqKtjvftVRGC', previewUrl: 'https://p.scdn.co/mp3-preview/9855b97cd3b6b299c199dc7eba946344374b8ab7?cid=774b29d4f13844c495f206cafdad9c86' },
        page3: { id: '17yRlMLWjrw82aWpJItT9A', previewUrl: 'https://p.scdn.co/mp3-preview/84d7d2d9924776059598fcc9857a40ecd6411acb?cid=774b29d4f13844c495f206cafdad9c86' },
        page4: { id: '698ItKASDavgwZ3WjaWjtz', previewUrl: 'https://p.scdn.co/mp3-preview/452d0f90e368128ef75f937c07d2011df7457221?cid=774b29d4f13844c495f206cafdad9c86' }
      },
      {
        page1: null,
        page2: { id: '0GSCAqCsmp7OVUWwC6sSgT', previewUrl: 'https://p.scdn.co/mp3-preview/6177b23f8a205ed3b4ef58deb46e68b17e69290c?cid=774b29d4f13844c495f206cafdad9c86' },
        page3: { id: '53BHUFdQphHiZUUG3nx9zn', previewUrl: 'https://p.scdn.co/mp3-preview/d7b9e7373a8bb67695c1f2c68e78f812a0df56c6?cid=774b29d4f13844c495f206cafdad9c86' },
        page4: ANTHEM_TRACK
      }
    ],
    pro: [
      { id: '7uGq08EZFUOh88CuEYNso0', previewUrl: 'https://p.scdn.co/mp3-preview/c6a33dd27a7b197712e0a709bffc7abb71c53c2b?cid=774b29d4f13844c495f206cafdad9c86' },
      { id: '06KyNuuMOX1ROXRhj787tj', previewUrl: 'https://p.scdn.co/mp3-preview/9c497e095198c3f26f98c244de8219935a908b81?cid=774b29d4f13844c495f206cafdad9c86' },
      { id: '0zhzkkSXFj60Y5fzb2j9hU', previewUrl: 'https://p.scdn.co/mp3-preview/b1d705126206d8ea2d9b76e88016dc287355df91?cid=774b29d4f13844c495f206cafdad9c86' },
      { id: '2TZ9kaTiLewawHZK7yylSC', previewUrl: 'https://p.scdn.co/mp3-preview/5a7fef747374a83fa4142cac77ea1c6b0b3ff3d8?cid=774b29d4f13844c495f206cafdad9c86' },
      { id: '2H3x7ADSwqgnBeYWdAZ45S', previewUrl: 'https://p.scdn.co/mp3-preview/416273bad48f8d7399a0c9e3eb7f99262e50f40f?cid=774b29d4f13844c495f206cafdad9c86' }
    ],
    champion: { id: '7hq8Hvxmqw5muIwGDUCgMH', previewUrl: 'https://p.scdn.co/mp3-preview/1d07e3146b531fee7a2d64f886b8643cbe0a9b49?cid=774b29d4f13844c495f206cafdad9c86' },
    skill: { id: '5YvYKEQEYJp4orh4z3Dnpk', previewUrl: 'https://p.scdn.co/mp3-preview/d0c3317c8e3a2e741fd8b7739a4b927e21d45b0d?cid=774b29d4f13844c495f206cafdad9c86' },
    easterEgg: { id: '1aErP1CfgNuZOjsLTuun2C', previewUrl: 'https://p.scdn.co/mp3-preview/6cd0c624472385f91dcb1dc81f62bef23127261a?cid=774b29d4f13844c495f206cafdad9c86' }
  },
  shanghai: {
    storytelling: [
      {
        page1: null,
        page2: { id: '7Hp29LdH3DaCi9dr6nV2SY', previewUrl: 'https://p.scdn.co/mp3-preview/542757a2821d61158928c1081523b9e70786e725?cid=774b29d4f13844c495f206cafdad9c86' },
        page3: { id: '5o6FVlT9cnYB4xzCIYkqtA', previewUrl: 'https://p.scdn.co/mp3-preview/63d6672a99a6fe6b785ee28d5ed021791f22f9f6?cid=774b29d4f13844c495f206cafdad9c86' },
        page4: { id: '68BmNLC0yh4mGWf1qNNSlo', previewUrl: 'https://p.scdn.co/mp3-preview/829487f582a96a5410118f04a36e56cd659f9ec6?cid=774b29d4f13844c495f206cafdad9c86' }
      },
      {
        page1: null,
        page2: { id: '5o6FVlT9cnYB4xzCIYkqtA', previewUrl: 'https://p.scdn.co/mp3-preview/63d6672a99a6fe6b785ee28d5ed021791f22f9f6?cid=774b29d4f13844c495f206cafdad9c86' },
        page3: { id: '2rTnVB1bvwxHtaIl4uVu7f', previewUrl: 'https://p.scdn.co/mp3-preview/975bf256e64cdf20ad6198763787ace676e49dc6?cid=774b29d4f13844c495f206cafdad9c86' },
        page4: { id: '1FpVJ7HpZInE2GvhVE2TwT', previewUrl: 'https://p.scdn.co/mp3-preview/2ada8eb10486d7ce23437d6825b280bd85896f66?cid=774b29d4f13844c495f206cafdad9c86' }
      },
      {
        page1: null,
        page2: { id: '3ZCVJX4jXNlsrFYbiaEzOd', previewUrl: 'https://p.scdn.co/mp3-preview/dfc001e48df8f1c5af59bb1628ebfe7460605fc4?cid=774b29d4f13844c495f206cafdad9c86' },
        page3: { id: '0n2SEXB2qoRQg171q7XqeW', previewUrl: 'https://p.scdn.co/mp3-preview/9f977ebb2e695502c8b1d3bd678ef706c9ea4ccb?cid=774b29d4f13844c495f206cafdad9c86' },
        page4: ANTHEM_TRACK
      }
    ],
    pro: [
      { id: '289IhpqgR1pMAH8UzxazwG', previewUrl: 'https://p.scdn.co/mp3-preview/e6ae078ed43734f3e0ce10b223c6ed7fbb96e250?cid=774b29d4f13844c495f206cafdad9c86' },
      { id: '68BmNLC0yh4mGWf1qNNSlo', previewUrl: 'https://p.scdn.co/mp3-preview/829487f582a96a5410118f04a36e56cd659f9ec6?cid=774b29d4f13844c495f206cafdad9c86' },
      { id: '7xHWNBFm6ObGEQPaUxHuKO', previewUrl: 'https://p.scdn.co/mp3-preview/7e073bac3a70223e4e5382668d050e240e237927?cid=774b29d4f13844c495f206cafdad9c86' },
      { id: '1uQ1TZ9YIWCtBTgq09uoix', previewUrl: 'https://p.scdn.co/mp3-preview/d3621f9d536ff8b0dd21dc1597f92ed69ef8cbc5?cid=774b29d4f13844c495f206cafdad9c86' },
      { id: '3BNy2wqDd44HLf5U9L5DFL', previewUrl: 'https://p.scdn.co/mp3-preview/f337806a3f476e94e3ab320d9ab5cba72922bd91?cid=774b29d4f13844c495f206cafdad9c86' }
    ],
    champion: { id: '0aGCZGwq3GxEWXWIALi7LK', previewUrl: 'https://p.scdn.co/mp3-preview/24dd411378878ca9c09330e6af1f900b5f01d2e3?cid=774b29d4f13844c495f206cafdad9c86' },
    skill: { id: '7JrSIPcfkWhDzxWII8Jz7V', previewUrl: 'https://p.scdn.co/mp3-preview/b48a486d386a11eb84aa4ee6a05bfaa6898dba32?cid=774b29d4f13844c495f206cafdad9c86' },
    easterEgg: { id: '30AMR8maWOQ8DrkI1QqPoA', previewUrl: 'https://p.scdn.co/mp3-preview/493c54c6b59ead9d403cf59f9176b01f8d050536?cid=774b29d4f13844c495f206cafdad9c86' }
  }
};
