import create, { UseStore } from 'zustand';

export interface LoaderStoreType {
  globalLoadProgress: number;
  setGlobalLoadProgress: (progress: number) => void;
}

const useLoaderStore: UseStore<LoaderStoreType> = create((set) => {
  return {
    globalLoadProgress: 0,
    setGlobalLoadProgress: (progress) => {
      set(() => ({
        globalLoadProgress: progress
      }));
    }
  }
});

export default useLoaderStore;
