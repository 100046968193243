import create, { UseStore } from 'zustand';

export interface CardScreenStoreType {
  currentCardScreen: '' | 'welcome' | 'playlist';
  setCurrentCardScreen: (
    type: CardScreenStoreType['currentCardScreen'],
    shouldAutoExit?: boolean,
    onExit?: CardScreenStoreType['onExit']
  ) => void;
  onExit: () => void;
  setOnExit: (onExit?: CardScreenStoreType['onExit']) => void;
  shouldAutoExit: boolean;
  setShouldAutoExit: (state: boolean) => void;
  playlistCardExiting: boolean;
  setPlaylistCardExiting: (state: boolean) => void;
}

const useCardScreenStore: UseStore<CardScreenStoreType> = create((set) => {
  return {
    currentCardScreen: '',
    setCurrentCardScreen: (type, shouldAutoExit = false, onExit = () => null) => {
      set((state) => ({
        ...state,
        currentCardScreen: type,
        shouldAutoExit,
        ...(type ? { onExit } : {})
      }));
    },
    onExit: () => null,
    setOnExit: (onExit = () => null) => {
      set(() => ({
        onExit
      }));
    },
    shouldAutoExit: false,
    setShouldAutoExit: (state) => {
      set(() => ({
        shouldAutoExit: state
      }));
    },
    playlistCardExiting: false,
    setPlaylistCardExiting: (state) => {
      set(() => ({
        playlistCardExiting: state
      }))
    }
  }
});

export default useCardScreenStore;
