import create, { UseStore } from 'zustand';

import { SoundFiles } from 'services/audioManager/audioManager.data';
import AudioManager from 'services/audioManager/audioManager.service';
import { setMuted as setLocalStorageMuted } from 'utils/localStorage';

export interface AudioStoreType {
  isMuted: boolean;
  isUserMuted: boolean;
  setMuted: (state: boolean, isFromUser?: boolean) => void;
  toggleMuted: () => void;

  currentLoopId: '' | SoundFiles;
  setCurrentLoopId: (loopId: SoundFiles) => void;
}

const useAudioStore: UseStore<AudioStoreType> = create((set, get) => {
  return {
    isMuted: false,
    isUserMuted: false,
    setMuted: (state, isFromUser = false) => {
      set(() => ({
        isMuted: state,
        isUserMuted: isFromUser
      }));

      if (isFromUser) setLocalStorageMuted(state);
      if (state) AudioManager.mute(isFromUser ? null : 0, isFromUser);
      else AudioManager.unmute(isFromUser ? null : 0, isFromUser);
    },
    toggleMuted: () => {
      const { isMuted, setMuted } = get();
      setMuted(!isMuted, true);
    },

    currentLoopId: '',
    setCurrentLoopId: (loopId) => {
      set(() => ({
        currentLoopId: loopId
      }));
    }
  };
});

export default useAudioStore;
