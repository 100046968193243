import { IconDirections } from 'components/ButtonHexa/ButtonHexa.styles';

export enum HotspotTypes {
  storytelling1 = 'storytelling1',
  storytelling2 = 'storytelling2',
  storytelling3 = 'storytelling3',
  pro = 'pro',
  champion = 'champion',
  skill = 'skill',
  easterEgg = 'easterEgg',
  location = 'location'
}

export enum HotspotOverlayTypes {
  Storytelling = 'Storytelling',
  Pro = 'Pro',
  Champion = 'Champion',
  Skill = 'Skill',
  EasterEgg = 'EasterEgg',
}

export interface Hotspot {
  type: HotspotTypes;
  label: string;
  position: { x: number; y: number; };
  roomNb: number;
  hasBeenSeen?: boolean;
  toRoomNb?: number;
  direction?: IconDirections;
  zIndex?: number;
}

export interface ClubhouseHotspots {
  storytelling: Hotspot[];
  pro: Hotspot;
  champion: Hotspot;
  skill: Hotspot;
  easterEgg: Hotspot;
  location: Hotspot[];
}
