import { ClubhousePageProps } from 'containers/ClubhousePage/ClubhousePage';
import { isStorybook } from 'u9/utils/platform';
import { ProHotspotOverlay } from 'store/copy.types';

import {
  STORYTELLING_IMAGES,
  STORYTELLING_VIDEOS,
  PRO_IMAGES,
  CHAMP_THUMBNAILS,
  CHAMP_IMAGES,
  SKILL_IMAGES,
  EASTER_EGG_IMAGES,
  DOWNLOADABLES
} from './config.assets';

export const LOCALES = (isStorybook() ? ['en', 'es', 'ko'] : process.env.LOCALES) as unknown as ('en' | 'es' | 'ko')[];

export const ISR_TIMEOUT = process.env.ENV === 'production' ? 300 : 10;

export const HOTSPOT_ASSETS = {
  STORYTELLING_IMAGES,
  STORYTELLING_VIDEOS,
  PRO_IMAGES,
  CHAMP_THUMBNAILS,
  CHAMP_IMAGES,
  SKILL_IMAGES,
  EASTER_EGG_IMAGES,
  DOWNLOADABLES
} as const;

export const CLUBHOUSE_SELECTOR_HEADING_WIDTH: Record<
  typeof LOCALES[number],
  Record<
    ClubhousePageProps['city'],
    Record<'desktop' | 'tablet' | 'mobile', number>
  >
> = {
  en: {
    seoul: {
      desktop: 850, // rem
      tablet: 700, // rem
      mobile: 500, // rem
    },
    berlin: {
      desktop: 1200, // rem
      tablet: 1000, // rem
      mobile: 500, // rem
    },
    shanghai: {
      desktop: 1300, // rem
      tablet: 1050, // rem
      mobile: 500, // rem
    }
  },
  ko: {
    seoul: {
      desktop: 1200, // rem
      tablet: 800, // rem
      mobile: 500, // rem
    },
    berlin: {
      desktop: 1200, // rem
      tablet: 800, // rem
      mobile: 500, // rem
    },
    shanghai: {
      desktop: 1200, // rem
      tablet: 800, // rem
      mobile: 500, // rem
    }
  },
  es: {
    seoul: {
      desktop: 850, // rem
      tablet: 700, // rem
      mobile: 500, // rem
    },
    berlin: {
      desktop: 1200, // rem
      tablet: 1000, // rem
      mobile: 500, // rem
    },
    shanghai: {
      desktop: 1300, // rem
      tablet: 1050, // rem
      mobile: 500, // rem
    }
  }
};

export const SECONDARY_OVERLAY_ANIMATE_IN_DELAY = 200; // ms

export const PRO_CHAMPS: Record<ClubhousePageProps['city'], ProHotspotOverlay['champs'][]> = {
  seoul: [
    [
      'zoe',
      'syndra',
      'leblanc',
      'akali'
    ],
    [
      'zoe',
      'sylas',
      'irelia',
      'orianna'
    ],
    [
      'leesin',
      'olaf',
      'nidalee',
      'lillia'
    ],
    [
      'azir',
      'ryze',
      'orianna',
      'galio'
    ],
    [
      'leesin',
      'graves',
      'nidalee',
      'sejuani'
    ]
  ],
  berlin: [
    [
      'sivir',
      'lucian',
      'ezreal',
      'tristana'
    ],
    [
      'gnar',
      'jayce',
      'ornn',
      'wukong'
    ],
    [
      'varus',
      'kalista',
      'xayah',
      'ashe'
    ],
    [
      'gnar',
      'gangplank',
      'camille',
      'aatrox'
    ],
    [
      'kaisa',
      'ezreal',
      'xayah',
      'varus'
    ]
  ],
  shanghai: [
    [
      'kaisa',
      'varus',
      'ezreal',
      'xayah'
    ],
    [
      'renekton',
      'gnar',
      'camille',
      'wukong'
    ],
    [
      'orianna',
      'syndra',
      'azir',
      'leblanc'
    ],
    [
      'gnar',
      'aatrox',
      'jayce',
      'kennen'
    ],
    [
      'kaisa',
      'varus',
      'aphelios',
      'ezreal'
    ]
  ]
};
