export const NAMESPACE = 'spotify-league-of-legends';

export const setMuted = (state: boolean) => {
  window.localStorage.setItem(`${NAMESPACE}.isMuted`, JSON.stringify(state));
};

export const getMuted = (): boolean => !!JSON.parse(window.localStorage.getItem(`${NAMESPACE}.isMuted`));

export const setBeenOnboarded = (state: boolean) => {
  window.localStorage.setItem(`${NAMESPACE}.hasBeenOnboarded`, JSON.stringify(state));
};

export const getBeenOnboarded = (): boolean => !!JSON.parse(window.localStorage.getItem(`${NAMESPACE}.hasBeenOnboarded`));
