import { IconDirections } from 'components/ButtonHexa/ButtonHexa.styles';
import { ClubhouseHotspots, HotspotTypes } from './clubhouse.types';


export const seoulHotspots: ClubhouseHotspots = {
  storytelling: [
    {
      type: HotspotTypes.storytelling1,
      label: '',
      position: { x: 71, y: 67 },
      hasBeenSeen: false,
      roomNb: 0,
      zIndex: 2
    },
    {
      type: HotspotTypes.storytelling2,
      label: '',
      position: { x: 57, y: 67 },
      hasBeenSeen: false,
      roomNb: 1
    },
    {
      type: HotspotTypes.storytelling3,
      label: '',
      position: { x: 36, y: 72 },
      hasBeenSeen: false,
      roomNb: 1
    }
  ],
  pro: {
    type: HotspotTypes.pro,
    label: '',
    position: { x: 88, y: 50 },
    hasBeenSeen: false,
    roomNb: 1
  },
  champion: {
    type: HotspotTypes.champion,
    label: '',
    position: { x: 45, y: 78 },
    hasBeenSeen: false,
    roomNb: 2
  },
  skill: {
    type: HotspotTypes.skill,
    label: '',
    position: { x: 73.2, y: 73.2 },
    hasBeenSeen: false,
    roomNb: 2
  },
  easterEgg: {
    type: HotspotTypes.easterEgg,
    label: '',
    position: { x: 40, y: 20 },
    hasBeenSeen: false,
    roomNb: 2
  },
  location: [
    {
      type: HotspotTypes.location,
      label: '',
      position: { x: 68, y: 52 },
      roomNb: 0,
      toRoomNb: 1
    },
    {
      type: HotspotTypes.location,
      label: '',
      position: { x: 65, y: 86 },
      roomNb: 1,
      toRoomNb: 2
    }
  ]
};

export const berlinHotspots: ClubhouseHotspots = {
  storytelling: [
    {
      type: HotspotTypes.storytelling1,
      label: '',
      position: { x: 46, y: 44 },
      hasBeenSeen: false,
      roomNb: 0
    },
    {
      type: HotspotTypes.storytelling2,
      label: '',
      position: { x: 84, y: 68 },
      hasBeenSeen: false,
      roomNb: 1
    },
    {
      type: HotspotTypes.storytelling3,
      label: '',
      position: { x: 40, y: 33 },
      hasBeenSeen: false,
      roomNb: 2
    }
  ],
  pro: {
    type: HotspotTypes.pro,
    label: '',
    position: { x: 79, y: 62 },
    hasBeenSeen: false,
    roomNb: 2
  },
  champion: {
    type: HotspotTypes.champion,
    label: '',
    position: { x: 24, y: 28 },
    hasBeenSeen: false,
    roomNb: 3
  },
  skill: {
    type: HotspotTypes.skill,
    label: '',
    position: { x: 38, y: 55 },
    hasBeenSeen: false,
    roomNb: 1
  },
  easterEgg: {
    type: HotspotTypes.easterEgg,
    label: '',
    position: { x: 67, y: 47 },
    hasBeenSeen: false,
    roomNb: 3
  },
  location: [
    {
      type: HotspotTypes.location,
      label: '',
      position: { x: 49.5, y: 26 },
      roomNb: 0,
      toRoomNb: 1
    },
    {
      type: HotspotTypes.location,
      label: '',
      position: { x: 55, y: 73 },
      roomNb: 1,
      toRoomNb: 2,
      direction: IconDirections.down
    },
    {
      type: HotspotTypes.location,
      label: '',
      position: { x: 50, y: 50 },
      roomNb: 1,
      toRoomNb: 3,
      direction: IconDirections.right
    }
  ]
};

export const shanghaiHotspots: ClubhouseHotspots = {
  storytelling: [
    {
      type: HotspotTypes.storytelling1,
      label: '',
      position: { x: 43, y: 68 },
      hasBeenSeen: false,
      roomNb: 0
    },
    {
      type: HotspotTypes.storytelling2,
      label: '',
      position: { x: 53, y: 42 },
      hasBeenSeen: false,
      roomNb: 1
    },
    {
      type: HotspotTypes.storytelling3,
      label: '',
      position: { x: 46, y: 22 },
      hasBeenSeen: false,
      roomNb: 1,
    }
  ],
  pro: {
    type: HotspotTypes.pro,
    label: '',
    position: { x: 68, y: 28 },
    hasBeenSeen: false,
    roomNb: 1
  },
  champion: {
    type: HotspotTypes.champion,
    label: '',
    position: { x: 79, y: 32 },
    hasBeenSeen: false,
    roomNb: 1
  },
  skill: {
    type: HotspotTypes.skill,
    label: '',
    position: { x: 51, y: 55 },
    hasBeenSeen: false,
    roomNb: 2
  },
  easterEgg: {
    type: HotspotTypes.easterEgg,
    label: '',
    position: { x: 92, y: 38 },
    hasBeenSeen: false,
    roomNb: 2
  },
  location: [
    {
      type: HotspotTypes.location,
      label: '',
      position: { x: 30, y: 63 },
      roomNb: 0,
      toRoomNb: 1
    },
    {
      type: HotspotTypes.location,
      label: '',
      position: { x: 35, y: 32 },
      roomNb: 1,
      toRoomNb: 2
    }
  ]
};
