import create, { UseStore } from 'zustand';

export interface HotspotOverlayStoreType {
  slideIndex: number;
  previousSlideIndex: number;
  setSlideIndex: (state: number) => void;
  setPreviousSlideIndex: (state: number) => void;
  isTransitioning: boolean;
  setTransitioning: (state: boolean) => void;
  blackOverlayTransition: 'in' | 'out' | 'none';
  setBlackOverlayTransition: (state: 'in' | 'out' | 'none') => void;
  mediaIsPlaying: boolean;
  setMediaIsPlaying: (state: boolean) => void;

  pagesCount: number;
  setPagesCount: (state: number) => void;
}

const useHotspotOverlayStore: UseStore<HotspotOverlayStoreType> = create((set) => {
  return {
    slideIndex: 0,
    setSlideIndex: (value) => {
      set(() => ({
        slideIndex: value
      }));
    },

    previousSlideIndex: 0,
    setPreviousSlideIndex: (value) => {
      set(() => ({
        previousSlideIndex: value
      }));
    },

    blackOverlayTransition: 'none',
    setBlackOverlayTransition: (value) => {
      set(() => ({
        blackOverlayTransition: value
      }));
    },

    mediaIsPlaying: false,
    setMediaIsPlaying: (value) => {
      set(() => ({
        mediaIsPlaying: value
      }));
    },

    pagesCount: 0,
    setPagesCount: (value) => {
      set(() => ({
        pagesCount: value
      }));
    },

    isTransitioning: false,
    setTransitioning: (value) => {
      set(() => ({
        isTransitioning: value
      }));
    },
  }
});

export default useHotspotOverlayStore;
