import React, { useCallback } from 'react';
import Marquee from 'react-fast-marquee';

import useWindowSize from 'u9/hooks/useWindowSize';
import { isBrowser } from 'u9/utils/platform';
import { isMobileLayout } from 'utils/styles/responsive';
import { ColorNames } from 'utils/styles/theme';

import { useMotion } from './Billboard.motion';
import * as Styled from './Billboard.styles';

export const validDirections = ['left', 'right'] as const;
export const validOrientations = ['upright', 'upsideDown'] as const;

export interface BillboardProps {
  copy?: string;
  textColor: ColorNames;
  mobileCopyCount?: number;
  mobileRows?: number;
  isScrolling?: boolean;
  orientation?: typeof validOrientations[number];
  direction?: typeof validDirections[number];
  speed?: number;
  animateInLetters?: boolean;
}

const Billboard = ({
  copy = '',
  textColor,
  mobileCopyCount = 3,
  mobileRows = 3,
  orientation = 'upright',
  isScrolling = false,
  direction = validDirections[0],
  speed = 100,
  animateInLetters = false,
}: BillboardProps) => {
  const size = useWindowSize();

  const { headingRef, wordsRef, wordsPerRow } = useMotion({
    isScrolling,
    direction,
    color: textColor,
    animateInLetters,
    mobileRows,
  });

  const repeatedCopy = useCallback(
    (rowIndex: number) => {
      let count = 1;
      let width = 100;
      if (isBrowser() && isScrolling) {
        const { width } = size;
        count = copy.length > 0 ? Math.max(width / copy.length / 10, 1) : 0;
      }
      if (!isScrolling && isMobileLayout()) {
        count = 1;
        width = (mobileRows / mobileCopyCount) * 100;
      }
      return [...Array(Math.ceil(count))].map((e, i) => {
        const wordIndex = wordsPerRow.current * rowIndex + i;
        const willAnimateWord = i < wordsPerRow.current;
        if (willAnimateWord) {
          wordsRef.current[wordIndex] = [];
        }
        return (
          <Styled.Copy key={i} width={width} isScrolling={isScrolling}>
            {animateInLetters
              ? Array.from(copy).map((letter, letterIndex) => (
                <Styled.Letter
                  key={letterIndex}
                  color={textColor}
                  filled={!animateInLetters || !willAnimateWord}
                  ref={el => {
                    if (willAnimateWord) {
                      wordsRef.current[wordIndex][letterIndex] = el;
                    }
                  }}
                >
                  {letter}
                </Styled.Letter>
              ))
              : copy}
          </Styled.Copy>
        );
      });
    },
    [
      animateInLetters,
      size,
      copy,
      isScrolling,
      mobileCopyCount,
      mobileRows,
      textColor,
      wordsRef,
      wordsPerRow,
    ]
  );

  return (
    <Styled.Wrapper>
      {animateInLetters ? (
        <Styled.CopyWrapper ref={headingRef}>
          <Styled.Heading
            color={textColor}
            isScrolling={false}
            orientation={orientation}
          >
            {Array(isMobileLayout() ? mobileRows : 1)
              .fill(null)
              .map((_, index) => (
                <Styled.InnerWrapper
                  key={index}
                  isScrolling={isScrolling}
                  isMobile={isMobileLayout()}
                  wrapRows={mobileRows > 1}
                >
                  {repeatedCopy(index)}
                </Styled.InnerWrapper>
              ))}
          </Styled.Heading>
        </Styled.CopyWrapper>
      ) : (
        <Marquee
          play={isScrolling}
          speed={speed}
          gradient={false}
          pauseOnHover={!isScrolling}
          pauseOnClick={!isScrolling}
          direction={direction}
        >
          <Styled.CopyWrapper ref={headingRef}>
            <Styled.Heading
              color={textColor}
              isScrolling={isScrolling}
              orientation={orientation}
            >
              {Array(isMobileLayout() ? mobileRows : 1)
                .fill(null)
                .map((_, index) => (
                  <Styled.InnerWrapper
                    isScrolling={isScrolling}
                    isMobile={isMobileLayout()}
                    key={index}
                    wrapRows={mobileRows > 1}
                    forceFlex
                  >
                    {repeatedCopy(index)}
                  </Styled.InnerWrapper>
                ))}
            </Styled.Heading>
          </Styled.CopyWrapper>
        </Marquee>
      )}
    </Styled.Wrapper>
  );
};

export default React.memo(Billboard);
