import { Howl } from 'howler';
import gsap from 'gsap';

import { isStorybook } from 'u9/utils/platform';
import { SoundData } from './audioManager.data';

const IS_DEBUG = isStorybook() || process.env.IS_DEBUG && false;

const DEFAULT_PLAY_CONFIG = {
  fadeDuration: 0,
  from: 0,
  delay: 0,
  volume: null,
  onEnd: null,
};

export default class Sound {
  id: SoundData['id'];
  type: SoundData['type'];
  onLoaded: () => void = null;
  defaultVolume = 1;
  volumeTween: gsap.core.Tween;
  isLoaded: boolean;
  sound: Howl;

  constructor(data: SoundData, onLoaded?: () => void) {
    this.id = data.id;
    this.type = data.type;
    this.onLoaded = onLoaded;
    this.defaultVolume = data.volume;
    this.volumeTween = null;
    this.isLoaded = false;

    // Force uncaching
    const url = data.file.startsWith('data:')
      ? data.file
      : `${data.file}${data.file.includes('?') ? '&' : '?'}timestamp=${Date.now()}`;

    if (IS_DEBUG) console.log('Sound -- Creating sound:', this.id, this, url);

    this.sound = new Howl({
      src: [url],
      format: ['mp3'],
      volume: this.defaultVolume,
      loop: data.loop,
      preload: true,
      onloaderror: (id, errorMessage) => {
        if (IS_DEBUG) console.log('Sound -- Unrecognized sound:', this.id, id, errorMessage);
      },
    });

    this.sound.once('load', () => {
      this.isLoaded = true;
      if (IS_DEBUG) console.log('Sound -- Loaded sound:', this.id, this);
      this.onLoaded();
    });
  }

  play = (config = DEFAULT_PLAY_CONFIG) => {
    if (!this.sound) return;

    config = { ...DEFAULT_PLAY_CONFIG, ...config };
    const { fadeDuration, from, onEnd, delay } = config;

    if (this.volumeTween) {
      this.volumeTween.kill();
      this.volumeTween = null;
    }

    if (fadeDuration) {
      const dummy = { value: 0 };

      this.volumeTween = gsap.fromTo(
        dummy,
        { value: 0 },
        {
          duration: fadeDuration,
          delay,
          value: config.volume ?? this.defaultVolume,
          onUpdate: () => {
            this.sound.volume(dummy.value);
          },
          onComplete: () => {
            this.volumeTween = null;
          },
        },
      );
    }

    gsap.delayedCall(delay, () => {
      if (onEnd) this.sound.once('end', onEnd);

      if (!fadeDuration) this.sound.volume(config.volume ?? this.defaultVolume);
      this.sound.seek(from);
      this.sound.play();
    });
  };

  stop = (fadeDuration?: number, callback?: () => void) => {
    if (!this.sound) return null;
    this.sound.off('end');

    if (this.volumeTween) {
      this.volumeTween.kill();
      this.volumeTween = null;
    }

    if (fadeDuration) {
      const dummy = { value: this.sound.volume() };

      this.volumeTween = gsap.to(dummy, {
        duration: fadeDuration,
        value: 0,
        onUpdate: () => {
          this.sound.volume(dummy.value);
        },
        onComplete: () => {
          this.volumeTween = null;
          if (callback) callback();
          this.sound.stop();
        },
      });

      return this.volumeTween;
    }

    if (callback) callback();
    this.sound.stop();
    return null;
  };

  setVolume = (volume: number, fadeDuration = 0, callback?: () => void) => {
    if (!this.sound) return;

    if (this.volumeTween) {
      this.volumeTween.kill();
      this.volumeTween = null;
    }

    if (fadeDuration) {
      const dummy = { value: this.sound.volume() };

      this.volumeTween = gsap.to(dummy, {
        duration: fadeDuration,
        value: volume,
        onUpdate: () => {
          this.sound.volume(dummy.value);
        },
        onComplete: () => {
          this.volumeTween = null;
          if (callback) callback();
        },
      });
    } else {
      this.sound.volume(volume);
      if (callback) callback();
    }
  };
}
