import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { ColorNames, colors } from 'utils/styles/theme';
import { mediaTablet } from 'utils/styles/responsive';

import { Alignment } from './RibbonCopy';

export const Wrapper = styled.div`
  position:relative;
  transform: translateY(-0.33em);

  ${mediaTablet(`
    transform: translateY(-0.55em);
  `)}
`;

export const Copy = styled.p<{ color: ColorNames; alignment?: Alignment; overflow?: boolean; }>`
    display: block;
    position: absolute;
    top: 0;
    ${({ theme: { locale } }) => setTypography(locale === 'ko' ? 'ribbonKorean' : 'ribbon', locale)}
    color: ${({ color }) => colors[color]};
    white-space: nowrap;
    ${({ alignment, theme: { locale } }) => {
    switch (alignment) {
      case Alignment.left:
        return `
          right: ${locale === 'ko' ? 60 : 50}rem;
        `
      default:
      case Alignment.right:
        return `
          left: ${locale === 'ko' ? 60 : 50}rem;
        `
    }
  }}
`;
export const Text = styled.span`
  display: inline-block;
`;
export const Slash = styled.span`
  margin: 0 ${({ theme: { locale } }) => { return locale === 'ko' ? '45rem' : '1em' }};
  display: inline-block;
`;
