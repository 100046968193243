export enum ColorNames {
  white = 'white',
  black = 'black',
  blueRibbon = 'blueRibbon',
  roti = 'roti',
  mountainMeadow = 'mountainMeadow',
  dustyGray = 'dustyGray',
  mercury = 'mercury',
  alto = 'alto',
  gray = 'gray',
  nobel = 'nobel',
  lightGray = 'lightGray'
}

export enum FontNames {
  circularStd = 'circularStd',
  presto = 'presto',
  doHyeon = 'doHyeon'
}

const validFontCategories = [
  'heading1',
  'heading2',
  'heading3',
  'heading4',
  'heading5',
  'heading6',
  'ribbon',
  'ribbonKorean',
  'leadBody',
  'leadBodyLighter',
  'leadBody2',
  'leadBody3',
  'body',
  'bodyLink',
  'bodySmallBook',
  'subtitle1',
  'subtitle2',
  'subtitle3',
  'link1',
  'link2',
  'menuLabel',
  'label',
  'caption',
  'footer',
  'button',
  'small'
] as const;

interface ResponsiveValues {
  mobile?: number;
  tablet?: number;
  desktop?: number;
}

interface FontCategory {
  fontFamily?: FontNames | Record<string, FontNames>;
  fontSize: ResponsiveValues;
  lineHeight: ResponsiveValues;
  fontWeight: number;
  letterSpacing?: number;
  wordSpacing?: number;
  fontStretch?: 'expanded' | 'normal';
  textDecoration?: 'underline' | 'none';
  textTransform?:
  | 'none'
  | 'capitalize'
  | 'uppercase'
  | 'full-size-kana'
  | 'full-width'
  | 'lowercase';
  fontStyle?:
  | 'normal'
  | 'italic'
  | 'oblique'
  | 'inherit'
  | 'initial'
  | 'unset';
}

interface Theme {
  colors: Record<ColorNames, string>;
  fonts: {
    face: Record<FontNames, string>;
    scale: Record<typeof validFontCategories[number], FontCategory>;
  };
  layout: typeof layout;
}

// "Name that Color" names
export const colors: Theme['colors'] = {
  [ColorNames.white]: '#fff',
  [ColorNames.black]: '#000',

  [ColorNames.blueRibbon]: '#0034f7', // Worlds Blue
  [ColorNames.roti]: '#c7a14f', // Worlds Gold
  [ColorNames.mountainMeadow]: '#1db954', // Spotify Green
  [ColorNames.dustyGray]: '#999',
  [ColorNames.mercury]: '#e5e5e5',
  [ColorNames.alto]: '#dedede',
  [ColorNames.gray]: '#808080',
  [ColorNames.nobel]: '#b4b4b4',
  [ColorNames.lightGray]: '#eaeaea',
} as const;

export const fonts: Theme['fonts'] = {
  face: {
    [FontNames.circularStd]: // eslint-disable-line quotes
      "'CircularStd', 'Helvetica Neue', Helvetica, Arial, sans-serif", // eslint-disable-line quotes, prettier/prettier
    [FontNames.presto]: // eslint-disable-line quotes
      "'Presto', 'Helvetica Neue', Helvetica, Arial, sans-serif", // eslint-disable-line quotes, prettier/prettier
    [FontNames.doHyeon]: // eslint-disable-line quotes
      "'DoHyeon', 'Helvetica Neue', Helvetica, Arial, sans-serif", // eslint-disable-line quotes, prettier/prettier
  },
  scale: {
    // Based on Material design typography scale, adjust as needed
    // fontSize: rem, letterSpacing: rem
    heading1: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 600, tablet: 300, mobile: 200 },
      lineHeight: { desktop: 1.265, tablet: 1.265, mobile: 1.265 },
      fontWeight: 900,
      fontStyle: 'italic',
      textTransform: 'uppercase',
    },
    heading2: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 360, tablet: 260, mobile: 160 },
      lineHeight: { desktop: 1.265, tablet: 1.265, mobile: 1.265 },
      fontWeight: 900,
      fontStyle: 'italic',
      textTransform: 'uppercase',
    },
    heading3: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 260, tablet: 180, mobile: 80 },
      lineHeight: { desktop: 1.025, tablet: 1.025, mobile: 1.025 },
      fontWeight: 900,
      fontStyle: 'italic',
      textTransform: 'uppercase',
    },
    heading4: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 180, tablet: 100, mobile: 110 },
      lineHeight: { desktop: 1.025, tablet: 1.025, mobile: 1.025 },
      fontWeight: 900,
      fontStyle: 'italic',
      textTransform: 'uppercase',
    },
    heading5: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 120, tablet: 100, mobile: 80 },
      lineHeight: { desktop: 1.265, tablet: 1.265, mobile: 1.265 },
      fontWeight: 900,
      fontStyle: 'italic',
      textTransform: 'uppercase',
    },
    heading6: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 70, tablet: 50, mobile: 80 },
      lineHeight: { desktop: 1.08, tablet: 1.08, mobile: 1.08 },
      fontWeight: 900,
      fontStyle: 'italic',
      textTransform: 'uppercase',
    },
    ribbon: {
      fontFamily: { default: FontNames.presto, ko: FontNames.doHyeon },
      fontStretch: 'expanded',
      fontSize: { desktop: 46, tablet: 36, mobile: 32 },
      lineHeight: { desktop: 0.96, tablet: 0.96, mobile: 0.96 },
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    ribbonKorean: {
      fontFamily: { default: FontNames.doHyeon },
      fontStretch: 'expanded',
      fontSize: { desktop: 60, tablet: 36, mobile: 36 },
      lineHeight: { desktop: 0.8, tablet: 0.8, mobile: 0.8 },
      wordSpacing: 30,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    leadBody: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 50, tablet: 32, mobile: 50 },
      lineHeight: { desktop: 1.23, tablet: 1.23, mobile: 1.23 },
      fontWeight: 500,
    },
    leadBodyLighter: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 50, tablet: 32, mobile: 50 },
      lineHeight: { desktop: 1.23, tablet: 1.23, mobile: 1.23 },
      fontWeight: 400,
    },
    leadBody2: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 40, tablet: 30, mobile: 44 },
      lineHeight: { tablet: 1.23, mobile: 1.23 },
      fontWeight: 400,
    },
    leadBody3: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 40, tablet: 34, mobile: 40 },
      lineHeight: { tablet: 1.23, mobile: 1.23 },
      fontWeight: 350,
    },
    body: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 40, tablet: 34, mobile: 42 },
      lineHeight: { desktop: 1.395, tablet: 1.395, mobile: 1.395 },
      fontWeight: 300,
    },
    bodyLink: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { tablet: 40, mobile: 34 },
      textDecoration: 'underline',
      lineHeight: { tablet: 1.395, mobile: 1.395 },
      fontWeight: 700,
    },
    bodySmallBook: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 32, tablet: 30, mobile: 40 },
      lineHeight: { desktop: 1.25, tablet: 1.25, mobile: 1.25 },
      fontWeight: 300,
    },
    subtitle1: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 44, tablet: 40, mobile: 38 },
      lineHeight: { desktop: 1.265, tablet: 1.265, mobile: 1.265 },
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 36, tablet: 24, mobile: 32 },
      lineHeight: { desktop: 1.265, tablet: 1.265, mobile: 1.265 },
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    subtitle3: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 36, tablet: 20, mobile: 40 },
      lineHeight: { desktop: 1.265, tablet: 1.265, mobile: 1.265 },
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    link1: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { tablet: 32, mobile: 32 },
      lineHeight: { tablet: 1.265, mobile: 1.265 },
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    link2: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { tablet: 32, mobile: 32 },
      lineHeight: { tablet: 1.265, mobile: 1.265 },
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    menuLabel: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 180, tablet: 100, mobile: 88 },
      lineHeight: { desktop: 1.025, tablet: 1.025, mobile: 1.025 },
      fontWeight: 900,
      fontStyle: 'italic',
      textTransform: 'uppercase',
    },
    label: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { desktop: 32, tablet: 28, mobile: 40 },
      lineHeight: { desktop: 1.265, tablet: 1.265, mobile: 1.265 },
      fontWeight: 700,
    },
    caption: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { tablet: 26, mobile: 26 },
      lineHeight: { desktop: 1.265, tablet: 1.265, mobile: 1.265 },
      fontWeight: 700,
      fontStyle: 'normal',
      textTransform: 'uppercase',
      letterSpacing: 0.8,
    },
    footer: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { tablet: 32, mobile: 32 },
      lineHeight: { tablet: 1.265, mobile: 1.265 },
      fontWeight: 400,
    },
    button: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { tablet: 26, mobile: 34 },
      lineHeight: { tablet: 1.265, mobile: 1.265 },
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    small: {
      fontFamily: { default: FontNames.circularStd, ko: FontNames.doHyeon },
      fontSize: { tablet: 20, mobile: 20 },
      lineHeight: { tablet: 1.265, mobile: 1.265 },
      fontWeight: 700,
      textTransform: 'uppercase',
      letterSpacing: 0.8
    }
  },
} as const;

// Comment unit where applicable
export const layout = {
  hoverOpacity: 0.7,
  hoverBrightness: 1.1,
  headerFooterHeight: {
    mobile: 9, // vh
    desktop: 12, // vh,
    mobileLandscape: 22 // vh
  },
  headerFooterPadding: {
    mobile: 100, // rem
    tablet: 60, // rem
    desktop: 100, // rem
  },
  ribbonHeight: {
    normal: 100,// vh
    introDesktop: 76, // vh
  },
  zIndex: {
    // Global stacking context
    footer: 1,
    header: 11,
    mobileHeader: 12,
    overlays: 13,
    clubhouseIntro: 14,
    landingLoader: 10,
    landingBillboard: 4,
    landingRibbon: 1,
    landingClubhouseSelector: 1,
    nonFunctionals: 102,
    cookieBanner: 103,
    transitionBars: 100,
    aboveTransitionBars: 101,
    // Clubhouse stacking context
    clubhouseBgOverlay: 1,
    clubhouseHotspots: 8,
    clubhouseAboveHotspots: 9,
    // Landing page clubhouse selector
    clubhouseSelectorHeading: 10,
    clubhouseSelectorWhiteFlash: 9
  },
  headerCloseIconSize: {
    mobile: 75, // rem
    tablet: 70, // rem
    desktop: 80 // rem
  },
  audioToggleHeight: {
    mobile: 80, // rem
    desktop: 50 // rem
  },
  footerElementMargin: {
    desktop: 70, // rem
    tablet: 50, // rem
  },
  tooltipSize: 26, // rem
  playPauseSize: 60, // rem
  player: {
    height: 220, // rem
    tabletHeight: 200, // rem
    mobileHeight: 280, // rem
    padding: { default: 30, centered: 50 }, // rem
    paddingTablet: { default: 20, centered: 30 } // rem
  },
  hotspotOverlaySubtitle: {
    marginTop: {
      mobile: 70, // rem
      desktop: 4, // vh
      desktopExtra: 7, // vh
    },
    marginBottom: {
      mobile: 40, // rem
      desktop: 3, // vh
    }
  },
  overlayContentPadding: {
    tablet: { h: 120, w: 100 }, // rem
    desktop: { h: 160, w: 200 }, // rem
  },
  ribbon: {
    width: { mobile: 120, desktop: 112 }, // rem
  },
  storytellingPadding: {
    desktop: 220, // rem
    desktopTop: 280, // rem
    tablet: 150, // rem
    tablettop: 240, // rem
  },
  storytellingSubtitleMargin: {
    mobile: { top: 80 } // rem
  },
  secondaryHotspotOverlay: {
    contentWrapperPadding: {
      mobile: { top: 200 }, // rem
    }
  },
  secondaryOverlayPaddingTop: {
    desktop: 100, // rem
    tablet: 100, // rem
  },
  getStartedMargin: { bottom: 14 }, // rem
  iconSizes: { // rem
    seoul: {
      ribbon: { width: 80, height: 56 },
      header: { width: 92, height: 64 }
    },
    berlin: {
      ribbon: { width: 34, height: 68 },
      header: { width: 48, height: 92 }
    },
    shanghai: {
      ribbon: { width: 72, height: 44 },
      header: { width: 106, height: 66 }
    },
    default: {
      ribbon: { width: 48, height: 48 },
      header: { width: 48, height: 48 }
    }
  }
} as const;

const theme: Theme = {
  colors,
  fonts,
  layout
} as const;

export default theme;
