import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { ReactComponent as HexaRightSideSvg } from 'svgs/buttonHexaRightSide.svg';
import { ReactComponent as HexaLeftSideSvg } from 'svgs/buttonHexaLeftSide.svg';

import { colors, ColorNames, layout } from 'utils/styles/theme';
import { hover, easeOutSine, setVh } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const ButtonSizes = {
  regular: {
    width: 120,
    height: 100,
    innerHexagonSizeDifference: 25,
  },
  large: {
    width: 180,
    height: 150,
    innerHexagonSizeDifference: 15,
  },
};

export const validThemes = {
  white: {
    primary: colors[ColorNames.white],
    secondary: colors[ColorNames.blueRibbon],
  },
  blue: {
    primary: colors[ColorNames.blueRibbon],
    secondary: colors[ColorNames.white],
  },
  golden: {
    primary: colors[ColorNames.roti],
    secondary: colors[ColorNames.white],
  },
};

export enum IconDirections {
  up = '0',
  right = '90deg',
  down = '180deg',
  left = '270deg',
}

const iconSizes = {
  chevron: '20rem',
  storytelling: '50.48rem',
  'vertical-chevrons': '32rem',
  profile: '42rem',
  champion: '30rem',
  skill: '58rem',
  'easter-egg': '30rem',
};

const colorTransition = `transition: color 0.2s ${easeOutSine}`;

type WrapperProps = {
  size: keyof typeof ButtonSizes;
  theme: keyof typeof validThemes;
  isEntering?: boolean;
  isBackButton?: boolean;
};

export interface BackingProps extends WrapperProps {
  hideOuterRing: boolean;
  theme: keyof typeof validThemes;
}

const BackingContainer = styled(motion.div) <BackingProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  svg {
    ${colorTransition};
    color: ${({ theme }) => validThemes[theme].primary};
  }
`;

export const BackingWrapper = styled(BackingContainer) <{
  isAnchoredRight?: boolean;
}>`
  width: calc(
    100% - ${({ size }) => ButtonSizes[size].innerHexagonSizeDifference}%
  );
  height: calc(
    100% - ${({ size }) => ButtonSizes[size].innerHexagonSizeDifference}%
  );
  top: ${({ size }) => ButtonSizes[size].innerHexagonSizeDifference / 2}%;
  left: ${({ size }) => ButtonSizes[size].innerHexagonSizeDifference / 2}%;
  svg {
    fill: currentColor;
  }

  justify-content: ${({ isAnchoredRight }) =>
    isAnchoredRight ? 'flex-start' : 'flex-end'};
`;

export const OuterRingWrapper = styled(BackingContainer) <{
  strokeLength: number;
}>`
  svg {
    width: 100%;
    fill: ${({ hideOuterRing }) => (hideOuterRing ? 'currentColor' : 'none')};
  }
  ${({ hideOuterRing, strokeLength }) =>
    !hideOuterRing
      ? css`
          transform: rotate(120deg);
          stroke-dasharray: ${strokeLength}px;
          stroke-dashoffset: ${strokeLength}px;
        `
      : ''}
`;

export const IdleRingAnimation = styled(BackingContainer) <{
  hovered?: boolean;
}>`
  opacity: 0.8;

  ${({ hovered }) =>
    hovered
      ? css`
          width: 115%;
          height: 115%;
          top: -7.5%;
          left: -7.5%;
        `
      : ''}

  svg {
    width: 100%;
  }
`;

export const IconWrapper = styled.div<{
  theme: keyof typeof validThemes;
  direction: IconDirections;
  icon: string;
  isBackButton: boolean;
}>`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  ${colorTransition};
  color: ${({ theme }) => validThemes[theme].secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateZ(${({ direction }) => direction});
  svg {
    width: ${props => iconSizes[props.icon]};
  }
  ${props => props.icon === 'chevron' ? mediaTablet(`
    svg {
      width: 24rem;
    }
  `) : ''}；
`;

export const Wrapper = styled(motion.button) <WrapperProps>`
  position: relative;
  padding: 0;
  border: none;
  width: ${({ size, isBackButton }) => isBackButton ? setVh(layout.headerFooterHeight.desktop * 0.3) : `${ButtonSizes[size].width}rem`};
  height: ${({ size, isBackButton }) => isBackButton ? setVh(layout.headerFooterHeight.desktop * 0.3) : `${ButtonSizes[size].height}rem`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-out;
  &:disabled {
    pointer-events: none;
    opacity: 0.2;
  }
  &:active {
    transform: scale(0.8);
  }
  ${({ size }) => mediaTablet(`
    width: ${ButtonSizes[size].width}rem;
    height: ${ButtonSizes[size].height}rem;
    `)}

  ${({ theme, isEntering }) => {
    return (
      !isEntering &&
      hover(`
      ${IconWrapper} {
        color: ${validThemes[theme].primary};
      }
      ${BackingContainer} {
        svg {
          color: ${validThemes[theme].secondary};
        }
      }
    `)
    );
  }}
`;

export const InnerWrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
`;

export const HexaMiddleDiv = styled.div<{ theme: keyof typeof validThemes }>`
  flex: 0.4;

  ${({ theme }) => css`
    background: ${validThemes[theme].primary};
  `}
`;

export const HexaLeftSide = styled(HexaLeftSideSvg)`
  position: absolute;
  height: 100%;
  left: 0;
`;

export const HexaRightSide = styled(HexaRightSideSvg)`
  position: absolute;
  height: 100%;
  right: 1%;
`;
