import { PlaylistData } from './playlists.types';

export interface User {
  id: string;
  name: string;
  image: string;
  hasPremium: boolean;
  favoritePlaylistData: PlaylistData;
}

export enum BlackBarTransitionState {
  In = 'In',
  Out = 'Out',
  None = 'None',
}

export enum BlackBarPositionState {
  Ribbon = 'Ribbon',
  Center = 'Center',
  Cover = 'Cover',
  Edge = 'Edge',
}
