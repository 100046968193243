import { useCallback, useEffect, useRef } from 'react';
import gsap from 'gsap';

import { isMobileLayout } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

import { validDirections } from './Billboard';

interface UseMotionProps {
  isScrolling?: boolean;
  copy?: boolean;
  direction: typeof validDirections[number];
  color?: string;
  animateInLetters?: boolean;
  mobileRows: number;
}

export const useMotion = ({
  isScrolling,
  direction,
  color,
  animateInLetters,
  mobileRows,
}: UseMotionProps) => {
  const timelineRef = useRef<gsap.core.Timeline>(null);
  const scrollingTimelineRef = useRef<gsap.core.Timeline>(null);
  const headingRef = useRef<HTMLDivElement>(null);
  const wordsRef = useRef<HTMLSpanElement[][]>([]);
  const animating = useRef(false);
  // Only use stroke animation for visible elements for better performance
  const wordsPerRow = useRef(4);

  const animateIn = useCallback(() => {
    if (timelineRef.current) timelineRef.current.kill();

    timelineRef.current = gsap.timeline({
      onComplete: () => {
        timelineRef.current = null;
      },
    });

    const totalDuration = 1;
    const stagger = totalDuration / wordsRef.current[0].length;

    const distancePercent = isMobileLayout() ? 100 : 50;

    const initialTextStroke = {
      '-webkit-text-stroke': `4rem ${colors[color]}`,
      '-webkit-text-fill-color': 'transparent',
    };

    const textStrokeAnimate = {
      '-webkit-text-stroke': 'initial',
      '-webkit-text-fill-color': colors[color],
      duration: 0.1,
      stagger,
    };

    if (isMobileLayout()) {
      Array(mobileRows)
        .fill(null)
        .forEach((_, rowIndex) => {
          const start = rowIndex * wordsPerRow.current;
          const end = (rowIndex + 1) * wordsPerRow.current;
          const wordsForRow = wordsRef.current.filter(
            (__, index) => index >= start && index < end
          );
          wordsForRow.forEach(lettersArray => {
            timelineRef.current.fromTo(
              lettersArray,
              { ...initialTextStroke },
              { ...textStrokeAnimate },
              0.3
            );
          });
        });
    } else {
      timelineRef.current
        .set(headingRef.current, { scale: 3 })
        .set(headingRef.current, { scale: 1 }, 0.4);
      wordsRef.current.forEach(letterArray => {
        timelineRef.current.delay(0.3).fromTo(
          letterArray,
          {
            ...initialTextStroke,
          },
          {
            ...textStrokeAnimate,
          }
        );
      });
    }

    // If not scrolling, slide in once
    if (!isScrolling) {
      timelineRef.current.fromTo(
        headingRef.current,
        {
          x:
            direction === 'right'
              ? `-${distancePercent}%`
              : `${distancePercent}%`,
        },
        {
          x: 0,
          duration: totalDuration,
          ease: 'power2.out',
        },
        0.2
      );
    }
  }, [direction, color, isScrolling, mobileRows]);

  const customScrolling = useCallback(() => {
    if (scrollingTimelineRef.current) scrollingTimelineRef.current.kill();

    scrollingTimelineRef.current = gsap.timeline({
      onComplete: () => {
        scrollingTimelineRef.current = null;
      },
    });

    const desiredSpeed = 120; // px/s
    const innerWidth = window.innerWidth;
    const totalWidth = 4 * innerWidth;
    const duration = totalWidth / desiredSpeed;

    scrollingTimelineRef.current.fromTo(
      headingRef.current,
      { x: (direction === 'left' ? 1 : -1) * 100 },
      {
        x: (direction === 'left' ? -1 : 1) * totalWidth,
        duration: duration,
        repeat: Infinity,
        ease: 'linear',
      }
    );
  }, [direction]);

  useEffect(() => {
    if (animateInLetters && !animating.current) {
      animating.current = true;
      animateIn();
    }
  }, [animateIn, animateInLetters]);

  useEffect(() => {
    if (isScrolling && animateInLetters) {
      customScrolling();
    }
  }, [isScrolling, animateInLetters, customScrolling]);

  useEffect(() => {
    return () => {
      if (timelineRef.current) {
        timelineRef.current.kill();
        timelineRef.current = null;
      }
      if (scrollingTimelineRef.current) {
        scrollingTimelineRef.current.kill();
        scrollingTimelineRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { headingRef, wordsRef, wordsPerRow };
};
