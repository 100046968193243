import React, {
  Fragment,
  useEffect,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
  MutableRefObject,
} from 'react';
import ReactFastMarquee from 'react-fast-marquee';
import gsap from 'gsap';

import { useGlobalStore } from 'store';
import { useSvgIcon } from 'u9/hooks/useSvgIcon';
import useWindowSize from 'u9/hooks/useWindowSize';
import { isBrowser } from 'u9/utils/platform';
import { ColorNames, layout } from 'utils/styles/theme';

import * as Styled from './Ribbon.styles';
import RibbonCopy, { Alignment, RibbonCopyRef } from './RibbonCopy/RibbonCopy';

export const validAlignments = ['vertical', 'horizontal'] as const;
export const validDirections = ['left', 'right'] as const;
export const validIcons = [
  'worlds-icon',
  'spotify-circle-white-black',
  'shanghai',
  'seoul',
  'berlin',
  ''
] as const;


export const RIBBON_ID = 'RibbonBarId';

export interface RibbonProps {
  copy: string;
  alignment?: typeof validAlignments[number];
  message?: string;
  forceEnterAnimation?: boolean;
  horizontalSpeed?: number;
  verticalSpeed?: number;
  direction?: typeof validDirections[number];
  characterCount?: number;
  textColor?: ColorNames;
  iconColor?: ColorNames;
  backgroundColor?: ColorNames;
  icon?: typeof validIcons[number];
  iconSize?: { width: number; height: number; };
  selfRibbonSlide?: boolean;
}

export interface RibbonRef {
  triggerAnimation: () => gsap.core.Timeline;
  innerWrapperRef: MutableRefObject<HTMLDivElement>;
}

const Ribbon = forwardRef<RibbonRef, RibbonProps>(
  (
    {
      copy,
      message = '',
      horizontalSpeed = 25,
      verticalSpeed = 0.08,
      direction = validDirections[0],
      alignment = 'horizontal',
      characterCount = null,
      textColor = ColorNames.white,
      iconColor = ColorNames.roti,
      backgroundColor = ColorNames.black,
      icon = validIcons[0],
      forceEnterAnimation = false,
      selfRibbonSlide = false,
      iconSize = layout.iconSizes.default.ribbon
    },
    ref
  ) => {
    const { isReady } = useGlobalStore(state => state);
    const size = useWindowSize();
    const { SvgIcon } = useSvgIcon(icon);

    const timeline = useRef<gsap.core.Timeline>(null);
    const leftCopy = useRef<RibbonCopyRef>(null);
    const rightCopy = useRef<RibbonCopyRef>(null);
    const messageRef = useRef<HTMLParagraphElement>(null);
    const messageTimeline = useRef<gsap.core.Timeline>(null);
    const leftArrowRef = useRef<HTMLDivElement>(null);
    const rightArrowRef = useRef<HTMLDivElement>(null);
    const innerWrapperRef = useRef<HTMLDivElement>(null);
    const iconRefs = useRef<HTMLDivElement[]>([]);
    const messageWrapperRef = useRef<HTMLDivElement>(null);
    const transitioning = useRef<boolean>(false);

    const wordCount = useMemo(() => {
      let count = 1;
      if (isBrowser()) {
        const { width, height } = size;
        switch (alignment) {
          case 'vertical':
            count = characterCount
              ? height / characterCount
              : height / copy.length / 2;
            break;
          case 'horizontal':
            count = characterCount
              ? width / characterCount
              : width / copy.length / 2;
            break;
          default:
          //
        }
      }
      return count;
    }, [size, alignment, characterCount, copy]);

    const showMessage = () => {
      if (messageTimeline.current) messageTimeline.current.kill();

      messageTimeline.current = gsap
        .timeline({
          onComplete: () => {
            timeline.current = null;
          },
        })
        .set(messageWrapperRef.current, { visibility: 'visible' })
        .fromTo(
          [rightArrowRef.current, leftArrowRef.current],
          {
            strokeDashoffset: i => (i % 2 === 0 ? -1 : 1) * 190,
            strokeDasharray: 190,
          },
          {
            strokeDashoffset: 0,
            ease: 'power2.in',
            duration: 0.5,
          }
        )
        .fromTo(
          rightArrowRef.current,
          {
            x: '-320rem',
          },
          {
            x: 0,
            duration: 0.5,
            ease: 'power3.in',
          }
        )
        .fromTo(
          leftArrowRef.current,
          {
            x: '320rem',
          },
          {
            x: 0,
            duration: 0.5,
            ease: 'power3.in',
          },
          '-=0.5'
        )
        .fromTo(
          iconRefs.current,
          {
            opacity: 0,
            x: i => (i === 0 ? '50rem' : '-50rem'),
          },
          {
            opacity: 1,
            x: 0,
            duration: 0.6,
            ease: 'power2.inOut',
          },
          '-=0.25'
        )
        .fromTo(
          messageRef.current,
          {
            opacity: 0,
            y: 10,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.2,
            ease: 'power2.out',
          },
          '-=0.4'
        );

      return messageTimeline.current;
    };

    const animateIn = () => {
      if (timeline.current) timeline.current.kill();
      timeline.current = gsap.timeline({
        delay: 0.5,
        onComplete: () => {
          transitioning.current = false;
          timeline.current = null;
        },
      });

      if (message) {
        gsap.set(messageWrapperRef.current, { visibility: 'hidden' });
      }

      if (leftCopy.current) {
        timeline.current.add(() => leftCopy.current.animateIn(), 0);
      }
      if (rightCopy.current) {
        timeline.current.add(() => rightCopy.current.animateIn(), 0);
      }
      if (message) {
        timeline.current.add(() => showMessage(), 0);
      }

      return timeline.current;
    };

    useEffect(() => {
      if (isReady && leftCopy.current?.animateIn && rightCopy.current?.animateIn && !transitioning.current) {
        transitioning.current = true;
        animateIn();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReady, leftCopy, rightCopy]);

    useImperativeHandle(
      ref,
      (): RibbonRef => ({
        triggerAnimation: animateIn,
        innerWrapperRef,
      })
    );

    useEffect(() => {
      if (!isReady) animateIn().pause(0);

      return () => {
        if (timeline.current) timeline.current.kill();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const repeatedCopy = selfRibbonSlide
      ? ` / ${copy} / ${copy} / ${copy} / ${copy} / ${copy} / ${copy}`
      : '';

    return (
      <Styled.Wrapper
        alignment={alignment}
        backgroundColor={backgroundColor}
        id={RIBBON_ID}
      >
        <Styled.InnerWrapper alignment={alignment} ref={innerWrapperRef} hasMessage={!!message || forceEnterAnimation}>
          {message || forceEnterAnimation ? (
            <>
              <RibbonCopy
                key={'ribbon-1'}
                copy={copy}
                alignment={Alignment.left}
                ref={leftCopy}
                color={textColor}
              />
              <Styled.MessageWrapper ref={messageWrapperRef}>
                <Styled.IconWrapper
                  color={iconColor}
                  ref={element => iconRefs.current.push(element)}
                  size={iconSize}
                >
                  {' '}
                  <SvgIcon />{' '}
                </Styled.IconWrapper>
                {message ? (
                  <>
                    <Styled.ArrowWrapper ref={leftArrowRef}>
                      <Styled.Arrow direction={'right'} />
                    </Styled.ArrowWrapper>
                    <Styled.Message
                      copy={''}
                      copyColor={textColor}
                      ref={messageRef}
                    >
                      {message}
                    </Styled.Message>
                    <Styled.ArrowWrapper ref={rightArrowRef}>
                      <Styled.Arrow direction={'left'} />
                    </Styled.ArrowWrapper>
                    <Styled.IconWrapper
                      color={iconColor}
                      ref={element => iconRefs.current.push(element)}
                      size={iconSize}
                    >
                      {' '}
                      <SvgIcon />{' '}
                    </Styled.IconWrapper>
                  </>
                ) : null}
              </Styled.MessageWrapper>
              <RibbonCopy
                key={'ribbon-2'}
                copy={copy}
                alignment={Alignment.right}
                ref={rightCopy}
                color={textColor}
                overflowCopy={repeatedCopy}
              />
            </>
          ) : (
            <ReactFastMarquee
              speed={
                alignment === 'vertical'
                  ? verticalSpeed
                  : horizontalSpeed
              }
              direction={direction}
              gradient={false}
              play={true}
            >
              {[...Array(Math.floor(wordCount))].map((_, index) => {
                return (
                  <Fragment key={index}>
                    <Styled.Copy
                      color={textColor}
                      hasExtraMargin={alignment === 'vertical' && iconSize && iconSize.height > iconSize.width}
                    >
                      {copy}
                    </Styled.Copy>
                    <Styled.IconWrapper
                      alignment={alignment}
                      color={iconColor}
                      size={iconSize}
                    >
                      {' '}
                      <SvgIcon />{' '}
                    </Styled.IconWrapper>
                  </Fragment>
                );
              })}
            </ReactFastMarquee>
          )}
        </Styled.InnerWrapper>
      </Styled.Wrapper>
    );
  }
);

export default React.memo(Ribbon);
