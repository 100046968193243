import styled from 'styled-components';

import { ReactComponent as ChevronIcon } from 'svgs/chevron-path.svg';

import { validAlignments } from './Ribbon';
import { colors, ColorNames, layout } from 'utils/styles/theme';
import { setTypography, setVh } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div<{
  alignment: typeof validAlignments[number];
  backgroundColor: ColorNames;
}>`
  position: relative;
  background: ${({ backgroundColor }) => colors[backgroundColor]};
  padding: ${({ alignment }) => (alignment === 'vertical' ? '0' : '30rem 0')};
  display: flex;

  ${({ alignment }) =>
    alignment === 'vertical'
      ? `
      width: ${layout.ribbon.width.mobile}rem;
      height: ${setVh(100)};

      ${mediaTablet(`
        width: ${layout.ribbon.width.desktop}rem;
      `)}
    `
      : `
      width: 100%;
      height: ${layout.ribbon.width.mobile}rem;

      ${mediaTablet(`
        height: ${layout.ribbon.width.desktop}rem;
      `)}
    `}

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ backgroundColor }) => colors[backgroundColor]};
    top: 0;
    transform: ${({ alignment }) => alignment === 'vertical' ? 'scaleY(1.5)' : 'scaleX(1.5)'};
    z-index: -1;
  }
`;

export const InnerWrapper = styled.div<{
  alignment: typeof validAlignments[number];
  hasMessage: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ alignment }) => (alignment === 'vertical' ? setVh(100) : '100%')};
  height: ${({ alignment }) => (alignment === 'vertical' ? '126rem' : 'auto')};
  position: relative;
  bottom: ${({ alignment }) =>
    alignment === 'vertical' ? '-100%;' : 'initial'};
  transform: ${({ alignment, hasMessage }) =>
    alignment === 'vertical' ? `${!hasMessage ? 'translateY(0.1em) ' : ''}rotateZ(270deg);` : `${!hasMessage ? 'translateY(0.1em) ' : ''}`};
  transform-origin: 0 0;
  ${({ alignment }) =>
    alignment === 'vertical'
      ? mediaTablet('height: 112rem;')
      : 'height: auto;'
  }

  .marquee-container {
    ${({ alignment }) =>
    alignment === 'vertical'
      ? 'overflow: visible !important;'
      : 'overflow: hidden;'
  }
  }
`;

export const MessageWrapper = styled.div`
  display: flex;
  justify-content:center;
  align-items:center;
`;

export const Copy = styled.p<{ color: ColorNames; hasExtraMargin: boolean }>`
  margin: 0 ${({ hasExtraMargin }) => hasExtraMargin ? 60 : 40}rem;
  ${({ theme: { locale } }) => setTypography(locale === 'ko' ? 'ribbonKorean' : 'ribbon', locale)}
  color: ${({ color }) => colors[color]};
`;

export const Message = styled.p<{ copy: string; copyColor: ColorNames }>`
  position: relative;
  margin: 0 32rem;
  ${({ theme: { locale } }) => setTypography('link2', locale)}
  color: ${colors[ColorNames.white]};
`;

export const ArrowWrapper = styled.div`
  display: flex;
  align-items:center;
`;

export const Arrow = styled(ChevronIcon) <{ direction: 'left' | 'right' }>`
  width: 24rem;
  height: 32rem;
  color: ${colors[ColorNames.white]};

  ${({ direction }) => {
    if (direction === 'left') {
      return `
        margin-right: 64rem;
      `;
    } else {
      return `
        margin-left: 64rem;
        transform: scaleX(-1);
      `;
    }
  }}
`;

export const IconWrapper = styled.div<{
  color: ColorNames;
  alignment?: typeof validAlignments[number];
  size?: {
    width: number;
    height: number;
  };
}>`
  color: ${({ color }) => colors[color]};
  position:relative;
  ${({ size }) => {
    if (size) {
      return `
        width: ${size.width}rem;
        height: ${size.height}rem;
      `;
    }
  }}
  transform: rotateZ(
    ${({ alignment }) => (alignment === 'vertical' ? '90deg' : '0deg')}
  );
  svg {
    width: 100%;
    height: 100%;
  }
`;
