import { useMemo } from 'react';

export const useSvgIcon = (icon: string) => {
  const SvgIcon = useMemo(() => {
    if (!icon) return null;
    const req = require.context('!@svgr/webpack!svgs/', false, /\.svg$/);
    return req(`./${icon}.svg`).default;
  }, [icon]);

  return { SvgIcon };
};

export default useSvgIcon;
