import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  DefaultOptions,
  DocumentNode,
  gql,
} from '@apollo/client';
import fetch from 'isomorphic-unfetch';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const uri = process.browser && process.env.ENV === 'local'
  ? process.env.CMS_GRAPHQL_URL_LOCAL
  : process.env.CMS_GRAPHQL_URL;

const httpLink = createHttpLink({
  uri,
  fetch,
});

export const cmsApiClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  defaultOptions,
  credentials: 'include'
});

export const getLocales = async () => await fetch(uri.replace('/graphql', '/i18n/locales')).then((data) => data.json());

export const getClubhouses = (): DocumentNode => gql`
  {
    clubhousePages {
      city
    }
  }
`;
