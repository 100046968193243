import styled, { css } from 'styled-components';
import { setTypography } from 'utils/styles/mixins';
import { validOrientations } from './Billboard';
import { ColorNames, colors } from 'utils/styles/theme';
import { mediaTablet } from 'utils/styles/responsive';
import { memo } from 'react';

export const Wrapper = styled.div`
  width: 100%;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type HeadingProps = {
  orientation?: typeof validOrientations[number];
  isScrolling: boolean;
  color: ColorNames;
};

export const Heading = styled.h2<HeadingProps>`
  ${({ theme: { locale } }) => setTypography('heading2', locale)};
  ${({ isScrolling }) => {
    if (!isScrolling) {
      return `
        width: 100%;
        text-align: center;
      `;
    }
  }};
  transform: rotateZ(
    ${({ orientation }) => (orientation === 'upsideDown' ? 180 : 0)}deg
  );
  color: ${({ color }) => colors[color]};
  margin: 0;
`;

export const InnerWrapper = styled.div<{
  isScrolling: boolean;
  isMobile: boolean;
  wrapRows?: boolean;
  forceFlex?: boolean;
}>`
  white-space: nowrap;
  overflow: visible;
  ${({ isScrolling, isMobile, wrapRows, forceFlex }) => {
    if (!isScrolling && isMobile) {
      return `
        overflow: hidden;
        width: 100vw;
        flex-wrap: wrap;
      `;
    }

    if (!isMobile && !wrapRows) {
      return `
        justify-content: center;
        align-items: center;
      `;
    }
    if (forceFlex) {
      return `
        display: flex;
      `;
    }
  }};
`;

export const Copy = memo(styled.span<{ width: number; isScrolling: boolean }>`
  min-width: 500rem; // to account for loading numbers rapidly changing widths
  width: ${({ width }) => width}%;
  overflow: visible;
  text-align: center;
  margin-right: ${({ isScrolling }) => (isScrolling ? 100 : 0)}rem;
  ${mediaTablet(`
     margin-right: 150rem;
     width: auto;
     min-width: 800rem;
    `)}
`);

export const CopyWrapper = styled.div``;

export const Letter = memo(styled.span<{ filled: boolean; color: string }>`
  ${({ filled, color }) =>
    !filled
      ? css`
          -webkit-text-stroke: 4rem ${color};
          -webkit-text-fill-color: transparent;
        `
      : ''};
`);
