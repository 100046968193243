import create, { UseStore } from 'zustand';

import { ClubhousePageProps } from 'containers/ClubhousePage/ClubhousePage';
import { seoulHotspots, berlinHotspots, shanghaiHotspots } from './clubhouse.data';
import { ClubhouseHotspots, Hotspot, HotspotTypes } from './clubhouse.types';
import { setBeenOnboarded as setLocalStorageBeenOnboarded } from 'utils/localStorage';

export interface ClubhouseStoreType {
  currentClubhouse: '' | ClubhousePageProps['city'];
  setCurrentClubhouse: (city: ClubhouseStoreType['currentClubhouse']) => void;
  currentHotspot: Hotspot;
  setCurrentHotspot: (hotspot: ClubhouseStoreType['currentHotspot']) => void;
  currentRoom: number; // 0: entry, >= 1: clubhouse rooms
  setCurrentRoom: (roomNumber: ClubhouseStoreType['currentRoom']) => void;

  hotspots: Record<ClubhousePageProps['city'], ClubhouseHotspots>;
  setClubhouseHotspots: (city: ClubhousePageProps['city'], clubhouseHotspots: Partial<ClubhouseHotspots>) => void;
  getSeenHotspotsCount: () => number;
  getClubhouseHotspotsSeenStates: (city: ClubhousePageProps['city'], hotspotTypes: HotspotTypes[]) => Hotspot['hasBeenSeen'][];

  isHotspotOverlayVisible: boolean;
  setHotspotOverlayVisible: (state: boolean) => void;

  isScrollable: boolean;
  setScrollable: (state: boolean) => void;
  hasBeenOnboarded: boolean;
  setBeenOnboarded: (state: boolean) => void;
  hasFirstScroll: boolean;
  setFirstScroll: (state: boolean) => void;
  isTransitioning: boolean;
  setTransitioning: (state: boolean) => void;
  onboardingStep: number; // 0-2
  setOnboardingStep: (step: number) => void;
  hasSeenSpotifyPlayer: boolean;
  setSeenSpotifyPlayer: (seen: boolean) => void;

  isLoading: boolean;
  setIsLoading: (state: boolean) => void;

  isReady: boolean;
  setReady: (state: boolean) => void;

  hasClubhousePreloaded: Record<ClubhousePageProps['city'], boolean>;
  setClubhousePreloaded: (city: ClubhousePageProps['city'], state: boolean) => void;

  hasCanvasLoaded: boolean;
  setCanvasLoaded: (state: boolean) => void;
}

const getSeenHotspotsCount = (hotspots: ClubhouseStoreType['hotspots']) => {
  return Object.values(hotspots).reduce((acc, clubhouseHotspots) => {
    return acc + Object.values(clubhouseHotspots).flat().filter((clubhouseHotspot: Hotspot) => clubhouseHotspot.hasBeenSeen).length;
  }, 0);
};

const getClubhouseHotspotsSeenStates = (hotspots: ClubhouseHotspots, types: HotspotTypes[]): Hotspot['hasBeenSeen'][] => {
  return types.map((hotspotType) => {
    const storytellingType = HotspotTypes.storytelling1.replace(/\d+/g, '');
    if (hotspotType.replace(/\d+/g, '') === storytellingType) {
      const storytellingIndex = parseInt(hotspotType.match(/\d+/)[0]) - 1;
      return hotspots[storytellingType][storytellingIndex].hasBeenSeen;
    }
    return hotspots[hotspotType].hasBeenSeen;
  });
};

const useClubhouseStore: UseStore<ClubhouseStoreType> = create((set, get) => {
  return {
    currentClubhouse: '',
    setCurrentClubhouse: (city) => {
      set(() => ({
        currentClubhouse: city
      }));
    },

    currentHotspot: null,
    setCurrentHotspot: (hotspot) => {
      set(() => ({
        currentHotspot: hotspot
      }));
    },

    currentRoom: 0,
    setCurrentRoom: (roomNumber) => {
      set(({ currentClubhouse, hotspots }) => ({
        currentRoom: !currentClubhouse
          ? 0
          : Math.max(Math.min(roomNumber, hotspots[currentClubhouse].location.length), 0)
      }));
    },

    hotspots: {
      seoul: seoulHotspots,
      berlin: berlinHotspots,
      shanghai: shanghaiHotspots
    },
    setClubhouseHotspots: (city, clubhouseHotspots) => {
      set(({ hotspots }) => ({
        hotspots: {
          ...hotspots,
          [city]: {
            ...hotspots[city],
            ...clubhouseHotspots
          }
        }
      }));
    },

    getSeenHotspotsCount: () => {
      const { hotspots } = get();
      return getSeenHotspotsCount(hotspots);
    },
    getClubhouseHotspotsSeenStates: (city, hotspotTypes) => {
      const { hotspots } = get();
      return getClubhouseHotspotsSeenStates(hotspots[city], hotspotTypes);
    },

    isHotspotOverlayVisible: false,
    setHotspotOverlayVisible: (state) => {
      set(() => ({
        isHotspotOverlayVisible: state
      }));
    },

    isScrollable: false,
    setScrollable: (state) => {
      set(() => ({
        isScrollable: state
      }));
    },

    isTransitioning: false,
    setTransitioning: (state) => {
      set(() => ({
        isTransitioning: state
      }));
    },

    hasBeenOnboarded: false,
    setBeenOnboarded: (state) => {
      set(() => ({
        hasBeenOnboarded: state
      }));

      setLocalStorageBeenOnboarded(state);
    },

    hasFirstScroll: false,
    setFirstScroll: (state) => {
      set(() => ({
        hasFirstScroll: state
      }));
    },

    onboardingStep: 0,
    setOnboardingStep: (step) => {
      set(() => ({
        onboardingStep: Math.max(Math.min(step, 2), 0)
      }));
    },

    hasSeenSpotifyPlayer: false,
    setSeenSpotifyPlayer: (seen) => {
      set(() => ({
        hasSeenSpotifyPlayer: seen
      }));
    },

    isLoading: false,
    setIsLoading: (state) => {
      set(() => ({
        isLoading: state
      }));
    },

    isReady: false,
    setReady: (state) => {
      set(() => ({
        isReady: state
      }));
    },

    hasClubhousePreloaded: {
      seoul: false,
      berlin: false,
      shanghai: false
    },
    setClubhousePreloaded: (clubhouse, hasPreloaded) => {
      set((state) => ({
        hasClubhousePreloaded: {
          ...state.hasClubhousePreloaded,
          [clubhouse]: hasPreloaded
        }
      }));
    },

    hasCanvasLoaded: false,
    setCanvasLoaded: (state) => {
      set(() => ({
        hasCanvasLoaded: state
      }));
    },
  };
});

export default useClubhouseStore;
