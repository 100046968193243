import create, { UseStore } from 'zustand';

import { isStorybook } from 'u9/utils/platform';

import { hotspotTracks, placeholderAnthemTrack, playlists } from './playlists.data';
import { Playlist, Track, PlaylistTypes, HotspotTracks } from './playlists.types';
import { ClubhousePageProps } from 'containers/ClubhousePage/ClubhousePage';

export interface PlaylistsStoreType {
  favoritedTracks: Track[];
  addTrackToFavorites: (track: Track) => PlaylistsStoreType['favoritedTracks'];
  removeTrackFromFavorites: (trackId: Track['id']) => PlaylistsStoreType['favoritedTracks'];
  setFavoritedTracks: (tracks: Track[]) => PlaylistsStoreType['favoritedTracks'];

  playlists: Record<PlaylistTypes, Playlist>;
  updatePlaylist: (type: PlaylistTypes, patch: Partial<Playlist>) => void;

  lastUnlockedPlaylist: '' | PlaylistTypes;
  setLastUnlockedPlaylist: (type: PlaylistsStoreType['lastUnlockedPlaylist']) => void;

  isPlaylistsOverlayVisible: boolean;
  togglePlaylistsOverlayVisible: () => void;

  anthemTrack: Track;
  setAnthemTrack: (track: Track) => void;

  hotspotTracks: Record<ClubhousePageProps['city'], HotspotTracks>;
  setClubhouseHotspotTracks: (city: ClubhousePageProps['city'], patch: Partial<HotspotTracks>) => void;

  initialFavoriteCount: number;
  setInitialFavoriteCount: (step: number) => void;

  isLoading: boolean;
  setLoading: (state: boolean) => void;
}

const usePlaylistsStore: UseStore<PlaylistsStoreType> = create((set, get) => {
  return {
    favoritedTracks: [],
    addTrackToFavorites: (track) => {
      set(({ favoritedTracks }) => ({
        favoritedTracks: Array.from(new Set([
          ...favoritedTracks,
          track
        ]))
      }));

      const { favoritedTracks } = get();
      return favoritedTracks;
    },
    removeTrackFromFavorites: (trackId) => {
      set(({ favoritedTracks }) => ({
        favoritedTracks: Array.from(new Set(favoritedTracks.filter((track) => track.id !== trackId)))
      }));

      const { favoritedTracks } = get();
      return favoritedTracks;
    },
    setFavoritedTracks: (tracks) => {
      set(() => ({
        favoritedTracks: Array.from(new Set([
          ...tracks
        ]))
      }));

      const { favoritedTracks } = get();
      return favoritedTracks;
    },

    playlists,
    updatePlaylist: (type, patch) => {
      set(({ playlists }) => ({
        playlists: {
          ...playlists,
          [type]: {
            ...playlists[type],
            ...patch
          }
        }
      }));
    },

    lastUnlockedPlaylist: '',
    setLastUnlockedPlaylist: (type) => {
      set(() => ({
        lastUnlockedPlaylist: type
      }));
    },

    isPlaylistsOverlayVisible: false,
    togglePlaylistsOverlayVisible: () => {
      set((state) => ({
        isPlaylistsOverlayVisible: !state.isPlaylistsOverlayVisible
      }));
    },

    anthemTrack: isStorybook() ? placeholderAnthemTrack : null,
    setAnthemTrack: (track) => {
      set(() => ({
        anthemTrack: track
      }));
    },

    hotspotTracks: {
      seoul: hotspotTracks,
      berlin: hotspotTracks,
      shanghai: hotspotTracks
    },
    setClubhouseHotspotTracks: (city, patch) => {
      set(({ hotspotTracks }) => ({
        hotspotTracks: {
          ...hotspotTracks,
          [city]: {
            ...hotspotTracks[city],
            ...patch
          }
        }
      }));
    },

    initialFavoriteCount: 0,
    setInitialFavoriteCount: (value) => {
      set(() => ({
        initialFavoriteCount: value
      }));
    },

    isLoading: false,
    setLoading: (state) => {
      set(() => ({
        isLoading: state
      }));
    },
  }
});

export default usePlaylistsStore;
