import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import gsap from 'gsap';

import { ColorNames } from 'utils/styles/theme';

import * as Styled from './RibbonCopy.styles';

export const validDirections = ['left', 'right'] as const;
export const validIcons = [
  'worlds-icon',
  'spotify-circle-white-black',
] as const;

export enum Alignment {
  left = 'left',
  right = 'right'
}

export interface RibbonCopyRef {
  animateIn: () => gsap.core.Timeline
}

export interface RibbonCopyProps {
  copy: string;
  alignment: Alignment;
  color: ColorNames;
  overflowCopy?: string;
}

const RibbonCopy = forwardRef<RibbonCopyRef, RibbonCopyProps>(({
  copy = '',
  alignment,
  color,
  overflowCopy = ''
}, ref) => {
  const slashes = useRef([]);
  const words = useRef([]);
  const timeline = useRef<gsap.core.Timeline>(null);
  const copyRef = useRef<string>(copy);

  const [shouldAnimate, setShouldAnimate] = useState(true);
  const [splitCopy, setSplitCopy] = useState([]);

  useEffect(() => {
    const splitCopy = copy.split(/\/+/gi);
    if (alignment === Alignment.right) {
      splitCopy.reverse();
    }
    setSplitCopy(splitCopy);
    slashes.current = [];
    words.current = [];
  }, [copy, alignment])

  const animateIn = useCallback(() => {
    return timeline.current?.play(0);
  }, []);

  useImperativeHandle(ref, () => ({
    animateIn
  }));

  useEffect(() => {
    if (!slashes.current || !words.current || !shouldAnimate) return;
    const textMovementModifier = alignment === Alignment.right ? -1 : 1;

    const orderedSlashes = alignment === Alignment.right ? slashes.current : [...slashes.current].reverse();
    const orderedWords = alignment === Alignment.right ? words.current : [...words.current].reverse();

    if (orderedSlashes.length === 0 ||
      orderedSlashes[0] === null ||
      orderedWords[0] === null ||
      orderedWords.length === 0
    ) return;

    gsap.set(
      orderedSlashes,
      {
        opacity: 0
      }
    )
    gsap.set(
      orderedWords,
      {
        opacity: 0
      }
    )
    if (timeline.current) timeline.current.kill();
    timeline.current = gsap
      .timeline({
        paused: true,
        onComplete: () => { timeline.current = null; }
      })
      .set(
        orderedSlashes,
        {
          opacity: 1,
          stagger: 0.1
        }
      )
      .fromTo(
        orderedSlashes,
        {
          scaleX: 2,
          scaleY: 10,
          skewX: -30
        },
        {
          scaleX: 1,
          scaleY: 1,
          skewX: 0,
          stagger: 0.3,
          duration: 0.5,
          ease: 'power3.out'
        },
        '-=0.3'
      )
      .fromTo(
        orderedWords,
        {
          opacity: 0,
          x: (i) => {
            if (i % 2 === 0) {
              return -50 * textMovementModifier
            }
            else {
              return 50 * textMovementModifier
            }
          }
        },
        {
          opacity: 1,
          x: 0,
          duration: 0.8,
          stagger: 0.2,
          ease: 'Circ.easeInOut',
        },
        '-=1.2'
      ).call(() => {
        setShouldAnimate(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slashes.current, words.current, shouldAnimate]);

  useEffect(() => {
    if (copyRef.current !== copy && timeline.current) {
      timeline.current.progress(1);
    }
    copyRef.current = copy;
  }, [copy]);

  useEffect(() => {
    return () => {
      if (timeline.current) timeline.current.kill();
    };
  }, [])

  return (
    <Styled.Wrapper>
      <Styled.Copy color={color} alignment={alignment}>
        {
          splitCopy.map((text, index) => {
            return (
              <span key={index}>
                <Styled.Text ref={ref => words.current[index] = ref}>{text}</Styled.Text>
                {
                  index < splitCopy.length - 1 &&
                  <Styled.Slash ref={ref => slashes.current[index] = ref}>/</Styled.Slash>
                }
              </span>
            )
          })
        }
        {overflowCopy}
      </Styled.Copy>
    </Styled.Wrapper>
  );
});

RibbonCopy.displayName = 'RibbonCopy';

export default React.memo(RibbonCopy);
