import LoopLanding from 'assets/sounds/bgs/1_Landing.mp3';
import LoopSeoulEntry from 'assets/sounds/bgs/3_Entry.mp3';
import LoopSeoulInterior from 'assets/sounds/bgs/3_Interior.mp3';
import LoopBerlinEntry from 'assets/sounds/bgs/7_Entry.mp3';
import LoopBerlinInterior from 'assets/sounds/bgs/7_Interior.mp3';
import LoopShanghaiEntry from 'assets/sounds/bgs/8_Entry.mp3';
import LoopShanghaiInterior from 'assets/sounds/bgs/8_Interior.mp3';

import SfxBehindBurnLong from 'assets/sounds/sfx/behindBurn_SFX_Long.mp3';
import SfxBehindBurnShort from 'assets/sounds/sfx/behindBurn_SFX_Short.mp3';
import SfxButtonClick from 'assets/sounds/sfx/buttonClick_SFX.mp3';
import SfxClubhouseHover from 'assets/sounds/sfx/clubhouseHover_SFX.mp3';
import SfxEnterRoom from 'assets/sounds/sfx/enterRoom_SFX.mp3';
import SfxFavoriteTrack from 'assets/sounds/sfx/favouriteTrack_SFX.mp3';
import SfxMusicDiscovered from 'assets/sounds/sfx/musicDiscovered_SFX.mp3';
import SfxOnboardPrompt from 'assets/sounds/sfx/onboardPrompt_SFX.mp3';
import SfxSelectClubhouse from 'assets/sounds/sfx/selectClubhouse_SFX.mp3';
import SfxSelectHotspot from 'assets/sounds/sfx/selectHotspot_SFX.mp3';
import SfxTracksAdded from 'assets/sounds/sfx/tracksAdded_SFX.mp3';

export enum SoundType {
  BGM = 'BGM',
  BGS = 'BGS',
  SFX = 'SFX',
}

export enum SoundFiles {
  loopLanding = 'loopLanding',
  loopSeoulEntry = 'loopSeoulEntry',
  loopSeoulInterior = 'loopSeoulInterior',
  loopBerlinEntry = 'loopBerlinEntry',
  loopBerlinInterior = 'loopBerlinInterior',
  loopShanghaiEntry = 'loopShanghaiEntry',
  loopShanghaiInterior = 'loopShanghaiInterior',

  behindBurnLong = 'behindBurnLong',
  behindBurnShort = 'behindBurnShort',
  buttonClick = 'buttonClick',
  clubhouseHover = 'clubhouseHover',
  enterRoom = 'enterRoom',
  favoriteTrack = 'favoriteTrack',
  musicDiscovered = 'musicDiscovered',
  onboardPrompt = 'onboardPrompt',
  selectClubhouse = 'selectClubhouse',
  selectHotspot = 'selectHotspot',
  tracksAdded = 'tracksAdded'
}

export interface SoundData {
  id: string;
  loop?: boolean;
  volume: number;
  type: SoundType;
  file: string;
}

export const defaultBGMData: Omit<SoundData, 'id'> = {
  loop: false,
  volume: 0.35,
  type: SoundType.BGM,
  file: ''
};

const data: Record<SoundFiles, Omit<SoundData, 'id'>> = {
  // BGS
  loopLanding: {
    loop: true,
    volume: 0.7,
    type: SoundType.BGS,
    file: LoopLanding
  },
  loopSeoulEntry: {
    loop: true,
    volume: 0.7,
    type: SoundType.BGS,
    file: LoopSeoulEntry
  },
  loopSeoulInterior: {
    loop: true,
    volume: 0.7,
    type: SoundType.BGS,
    file: LoopSeoulInterior
  },
  loopBerlinEntry: {
    loop: true,
    volume: 0.7,
    type: SoundType.BGS,
    file: LoopBerlinEntry
  },
  loopBerlinInterior: {
    loop: true,
    volume: 0.7,
    type: SoundType.BGS,
    file: LoopBerlinInterior
  },
  loopShanghaiEntry: {
    loop: true,
    volume: 0.7,
    type: SoundType.BGS,
    file: LoopShanghaiEntry
  },
  loopShanghaiInterior: {
    loop: true,
    volume: 0.7,
    type: SoundType.BGS,
    file: LoopShanghaiInterior
  },

  // SFX
  behindBurnLong: {
    loop: false,
    volume: 0.7,
    type: SoundType.SFX,
    file: SfxBehindBurnLong
  },
  behindBurnShort: {
    loop: false,
    volume: 0.7,
    type: SoundType.SFX,
    file: SfxBehindBurnShort
  },
  buttonClick: {
    loop: false,
    volume: 0.7,
    type: SoundType.SFX,
    file: SfxButtonClick
  },
  clubhouseHover: {
    loop: false,
    volume: 0.7,
    type: SoundType.SFX,
    file: SfxClubhouseHover
  },
  enterRoom: {
    loop: false,
    volume: 0.7,
    type: SoundType.SFX,
    file: SfxEnterRoom
  },
  favoriteTrack: {
    loop: false,
    volume: 0.7,
    type: SoundType.SFX,
    file: SfxFavoriteTrack
  },
  musicDiscovered: {
    loop: false,
    volume: 0.7,
    type: SoundType.SFX,
    file: SfxMusicDiscovered
  },
  onboardPrompt: {
    loop: false,
    volume: 0.7,
    type: SoundType.SFX,
    file: SfxOnboardPrompt
  },
  selectClubhouse: {
    loop: false,
    volume: 0.7,
    type: SoundType.SFX,
    file: SfxSelectClubhouse
  },
  selectHotspot: {
    loop: false,
    volume: 0.7,
    type: SoundType.SFX,
    file: SfxSelectHotspot
  },
  tracksAdded: {
    loop: false,
    volume: 0.7,
    type: SoundType.SFX,
    file: SfxTracksAdded
  }
} as const;

export const SOUNDS_DATA: Partial<Record<SoundFiles, SoundData>> = Object.keys(data).reduce(
  (acc, id) => ({
    ...acc,
    [id]: { id, ...data[id] },
  }),
  {},
);
