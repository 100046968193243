import create, { UseStore } from 'zustand';

import { BlackBarPositionState, BlackBarTransitionState, User } from './global.types';

export interface GlobalStoreType {
  isReady: boolean;
  setReady: (state: boolean) => void;

  isLoaderMotionComplete: boolean;
  setLoaderMotionComplete: (state: boolean) => void;

  user: User;
  setUser: (patch: Partial<User>) => void;

  accessToken: string;
  setAccessToken: (accessToken: string) => void;

  refreshToken: string;
  setRefreshToken: (refreshToken: string) => void;

  isAuthPromptVisible: boolean;
  setAuthPromptVisible: (state: boolean) => void;
  toggleAuthPromptVisible: () => void;

  isAnthemVideoVisible: boolean;
  setAnthemVideoVisible: (state: boolean) => void;
  toggleAnthemVideoVisible: () => void;

  blackBarTransition: BlackBarTransitionState;
  setBlackBarTransition: (
    type: BlackBarTransitionState,
    barStart: BlackBarPositionState,
    barEnd: BlackBarPositionState,
    onTransitionEnd?: GlobalStoreType['onTransitionEnd']
  ) => void;
  barStart: BlackBarPositionState;
  barEnd: BlackBarPositionState;
  onTransitionEnd: () => void;
  setTransitionEnd: (onTransitionEnd?: GlobalStoreType['onTransitionEnd']) => void;

  countryType: number; // 0: Uses "Cookie Policy", 1: Uses "Cookie Settings"
  setCountryType: (type: number) => void;

  isWindowTooSmall: boolean;
  setWindowTooSmall: (state: boolean) => void;

  shouldRotateDevice: boolean;
  setRotateDevice: (state: boolean) => void;
}

const useGlobalStore: UseStore<GlobalStoreType> = create((set, get) => {
  return {
    isReady: false,
    setReady: (state) => {
      set(() => ({
        isReady: state
      }));
    },

    isLoaderMotionComplete: false,
    setLoaderMotionComplete: (state) => {
      set(() => ({
        isLoaderMotionComplete: state
      }));
    },

    user: null,
    setUser: (patch) => {
      set((state) => patch === null ? null : ({
        user: {
          ...state.user,
          ...patch
        }
      }));
    },

    accessToken: '',
    setAccessToken: (accessToken) => {
      set(() => ({
        accessToken
      }));
    },

    refreshToken: '',
    setRefreshToken: (refreshToken) => {
      set(() => ({
        refreshToken
      }));
    },

    isAuthPromptVisible: false,
    setAuthPromptVisible: (state) => {
      set(() => ({
        isAuthPromptVisible: state
      }));
    },
    toggleAuthPromptVisible: () => {
      const { isAuthPromptVisible, setAuthPromptVisible } = get();
      setAuthPromptVisible(!isAuthPromptVisible);
    },

    isAnthemVideoVisible: false,
    setAnthemVideoVisible: (state) => {
      set(() => ({
        isAnthemVideoVisible: state
      }));
    },
    toggleAnthemVideoVisible: () => {
      const { isAnthemVideoVisible, setAnthemVideoVisible } = get();
      setAnthemVideoVisible(!isAnthemVideoVisible);
    },

    blackBarTransition: BlackBarTransitionState.None,
    barStart: BlackBarPositionState.Center,
    barEnd: BlackBarPositionState.Center,
    setBlackBarTransition: (type, barStart, barEnd, onTransitionEnd = () => null) => {
      set((state) => ({
        ...state,
        barStart,
        barEnd,
        blackBarTransition: type,
        onTransitionEnd
      }));
    },
    onTransitionEnd: () => null,
    setTransitionEnd: (onTransitionEnd = () => null) => {
      set(() => ({
        onTransitionEnd
      }));
    },

    countryType: null,
    setCountryType: (type) => {
      set(() => ({
        countryType: type
      }));
    },

    isWindowTooSmall: false,
    setWindowTooSmall: (state) => {
      set(() => ({
        isWindowTooSmall: state
      }));
    },

    shouldRotateDevice: false,
    setRotateDevice: (state) => {
      set(() => ({
        shouldRotateDevice: state
      }));
    }
  }
});

export default useGlobalStore;
