import axios from 'axios';

import { isTablet } from 'u9/utils/platform';

class PreloaderService {
  manifest = null;
  loading = {};
  files = {};

  totalLoaded = 0;
  totalToLoad = 0;

  load = async (
    directories: string[],
    onProgress: (progress: number) => void,
    onComplete: () => void
  ) => {
    if (!this.manifest) {
      try {
        const manifestURL = `${process.env.PUBLIC_URL}${process.env.PUBLIC_PATH}/asset-manifest.json?${Date.now()}`;
        const { data } = await axios.get(manifestURL);
        this.manifest = data;
      } catch (error) {
        if (process.env.IS_DEBUG) console.error(error);
        return;
      }
    }

    const fileIds = [];
    directories.forEach(directory => {
      Object.keys(this.manifest)
        .filter(key => key.startsWith(directory))
        .forEach(id => {
          fileIds.push(id);
        });
    });

    if (!fileIds.length) {
      onComplete();
      return;
    }

    const filesToLoad: string[] = fileIds.reduce((array, id) => {
      const skip = id
        .slice(0, -4)
        .endsWith(isTablet() ? '--phone' : '--tablet');
      const url = this.manifest[id];
      if (!this.files[url] && !skip) array.push(url);
      return array;
    }, []);

    this.totalLoaded = 0;
    this.totalToLoad = filesToLoad.length;

    if (this.totalToLoad > 0) {
      for (let i = 0; i < this.totalToLoad; i++) {
        const url = `${process.env.PUBLIC_URL}${process.env.PUBLIC_PATH}/${filesToLoad.shift()}`;
        await this.loadFile(url);
        this.totalLoaded++;
        onProgress((this.totalLoaded / this.totalToLoad) * 100);
        if (this.totalLoaded === this.totalToLoad) onComplete();
      }
    } else {
      onComplete();
    }
  };

  loadFile = (url: string) => {
    if (this.loading[url]) return this.loading[url];

    this.loading[url] = new Promise<string>(resolve => {
      if (!this.loading[url]) {
        this.loading[url] = true;
        const responseType =
          url.endsWith('.txt') || url.endsWith('.json')
            ? 'arraybuffer'
            : 'blob';

        axios
          .get(url, { responseType, transformResponse: undefined })
          .then(response => {
            if (responseType === 'blob') {
              const reader = new FileReader();
              reader.readAsDataURL(response.data);
              reader.onloadend = () => {
                this.files[url] = reader.result;
                resolve(this.files[url]);
              };
            } else {
              const buffer = Buffer.from(response.data, 'binary');
              this.files[url] = url.endsWith('.json')
                ? buffer.toJSON()
                : buffer.toString();
              resolve(this.files[url]);
            }
          });
      }
    });

    return this.loading[url];
  };

  get = (fileUrl: string, fallbackToFileUrl = true) => {
    if (fallbackToFileUrl)
      return !this.files[fileUrl] || fileUrl.startsWith('data:')
        ? fileUrl
        : this.files[fileUrl];

    return this.files[fileUrl];
  };
}

export default new PreloaderService();
