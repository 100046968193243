import { PlaylistTypes, Playlist, Track, HotspotTracks } from './playlists.types';

export const playlists: Record<PlaylistTypes, Playlist> = {
  personalized: {
    isUnlocked: false,
    unlockConditions: {
      favorites: 3
    },
    data: {
      id: '',
      url: '',
      tracks: []
    }
  }
};

export const placeholderAnthemTrack: Track = {
  id: '003vvx7Niy0yvhvHt4a68B',
  artists: ['The Killers'],
  artistIds: ['0C0XlULifJtAgn6ZNCW2eu'],
  title: 'Mr. Brightside',
  image: 'https://i.scdn.co/image/ab67616d0000b273ccdddd46119a4ff53eaf1f5d',
  url: 'https://open.spotify.com/track/003vvx7Niy0yvhvHt4a68B',
  previewUrl: 'https://p.scdn.co/mp3-preview/24898ec3c1c54986f9109ff99b55890943fc3c66?cid=774b29d4f13844c495f206cafdad9c86',
  isCurated: true
};

export const hotspotTracks: HotspotTracks = {
  storytelling: [
    { page1: null, page2: null, page3: null, page4: null },
    { page1: null, page2: null, page3: null, page4: null },
    { page1: null, page2: null, page3: null, page4: null }
  ],
  pro: [],
  champion: null,
  skill: null,
  easterEgg: null
};

export const placeholderHotspotTracks = {
  champion: placeholderAnthemTrack,
  easterEgg: placeholderAnthemTrack,
  skill: placeholderAnthemTrack,
  pro: [
    placeholderAnthemTrack,
    placeholderAnthemTrack,
    placeholderAnthemTrack,
    placeholderAnthemTrack,
    placeholderAnthemTrack
  ],
  storytelling: [
    {
      page1: null,
      page2: placeholderAnthemTrack,
      page3: placeholderAnthemTrack,
      page4: placeholderAnthemTrack
    },
    {
      page1: null,
      page2: placeholderAnthemTrack,
      page3: placeholderAnthemTrack,
      page4: placeholderAnthemTrack
    },
    {
      page1: null,
      page2: placeholderAnthemTrack,
      page3: placeholderAnthemTrack,
      page4: placeholderAnthemTrack
    }
  ]
}

export const placeholderFavoriteTracks: Track[] = [
  placeholderAnthemTrack,
  {
    ...placeholderAnthemTrack,
    isCurated: false
  },
  {
    ...placeholderAnthemTrack,
    isCurated: false
  }, {
    ...placeholderAnthemTrack,
    isCurated: false
  }
]
