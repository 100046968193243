import { Howler } from 'howler';
import gsap from 'gsap';

import { addEventListeners, removeEventListeners } from 'u9/utils/dom';
import { isStorybook } from 'u9/utils/platform';
import EventManager from 'services/eventManager.service';

import { SoundData, SOUNDS_DATA, SoundType } from './audioManager.data';
import Sound from './sound';

const IS_DEBUG = isStorybook() || process.env.IS_DEBUG && false;

export const DEFAULT_FADE_DURATION = 0.4;

const DEFAULT_SOUND_CONFIG = {
  fadeDuration: 0,
  from: 0,
  delay: 0,
  forceRestart: false,
  volume: null,
  onEnd: null,
};

class AudioManager {
  readonly cache: Record<string, Sound> = {};
  protected _globalVolume = 1;
  protected currentBGM = null;
  protected bgmFadeOutTween = null;
  protected muteTween = null;

  protected onLoadProgress: (progress: number) => void = null;
  onLoadComplete: () => void = null

  soundsLoaded = 0;

  get soundsCount() {
    return Object.keys(SOUNDS_DATA).length;
  }

  get globalVolume() {
    return this._globalVolume;
  }

  set globalVolume(value: number) {
    this._globalVolume = value;
    Howler.volume(this._globalVolume);
  }

  isReady = false;
  isMuted = false;
  isUserMuted = false;
  isUnlocked = false;

  eventManager: EventManager;

  constructor() {
    this.eventManager = new EventManager();
    this.eventManager.registerEvent('loadProgress');
    this.eventManager.registerEvent('loadComplete');
    this.eventManager.registerEvent('unlock');
  }

  init = (
    onLoadProgress: (progress: number) => void = null,
    onLoadComplete: () => void = null
  ) => {
    this.onLoadProgress = onLoadProgress;
    this.onLoadComplete = onLoadComplete;

    if (this.isReady) {
      if (this.soundsLoaded !== this.soundsCount) {
        this.isReady = false;
      } else {
        this.onLoadAllComplete();
        return;
      }
    }

    this.load();
  };

  destroy = () => {
    removeEventListeners(window, 'click touchend', this.unlockAudio);
  };

  play = (soundData: SoundData, config: Partial<typeof DEFAULT_SOUND_CONFIG> = DEFAULT_SOUND_CONFIG) => {
    if (!this.isUnlocked) return;

    // eslint-disable-next-line no-param-reassign
    const playConfig = { ...DEFAULT_SOUND_CONFIG, ...config };
    const { forceRestart, ...configRest } = playConfig;

    if (IS_DEBUG) console.log('AudioManager -- play', soundData.id, playConfig);

    const cachedSound = this.cache[soundData.id];
    if (cachedSound) {
      const isBGM = cachedSound.type === SoundType.BGM;
      const proceed = () => {
        cachedSound.play(configRest);

        if (isBGM) {
          this.currentBGM = cachedSound;
          this.bgmFadeOutTween = null;
        }
      };

      if (isBGM && this.currentBGM) {
        // Use a fade by default if a BGM is already playing
        const duration = playConfig.fadeDuration ?? DEFAULT_FADE_DURATION;

        if (this.currentBGM.id !== cachedSound.id || forceRestart) {
          if (this.bgmFadeOutTween) this.bgmFadeOutTween.kill();
          this.bgmFadeOutTween = this.currentBGM.stop(duration, proceed);
        }
      } else {
        proceed();
      }
    } else {
      if (IS_DEBUG) console.log('AudioManager -- playSound Unregistered sound:', SOUNDS_DATA);
    }
  };

  stop = (soundData: SoundData, fadeDuration?: number, callback?: () => void) => {
    if (!this.isUnlocked) return;
    if (IS_DEBUG) console.log('AudioManager -- stop', soundData.id);

    const cachedSound = this.cache[soundData.id];
    if (cachedSound) {
      cachedSound.stop(fadeDuration, () => {
        if (cachedSound.type === SoundType.BGM) this.currentBGM = null;
        if (callback) callback();
      });
    } else {
      if (IS_DEBUG) console.log('AudioManager -- stop Unregistered sound:', soundData);
    }
  };

  stopType = (type: SoundType, fadeDuration?: number, callback?: () => void) => {
    if (!this.isUnlocked) return;
    if (IS_DEBUG) console.log('AudioManager -- stopType', type);

    const sounds = Object.values(this.cache).filter((cachedSound) => {
      const soundData: SoundData = SOUNDS_DATA[cachedSound.id];
      return soundData.type === type;
    });

    let stoppedCount = 0;
    sounds.forEach((cachedSound) => {
      const soundData: SoundData = SOUNDS_DATA[cachedSound.id];
      this.stop(soundData, fadeDuration, () => {
        if (callback && ++stoppedCount === sounds.length) callback();
      });
    });
  };

  setGlobalVolume = (volume: number) => {
    this.globalVolume = volume;
  };

  setVolume = (soundData: SoundData, volume: number, fadeDuration?: number, callback?: () => void) => {
    const cachedSound = this.cache[soundData.id];
    if (cachedSound) cachedSound.setVolume(volume ?? cachedSound.defaultVolume, fadeDuration, callback);
    else {
      if (IS_DEBUG) console.log('AudioManager -- setVolume Unregistered sound:', soundData);
    }
  };

  unmute = (fadeDuration?: number, isFromUser = false) => {
    if (!this.isMuted) return;
    if (isFromUser) this.isUserMuted = false;

    // Don't unmute if the user chose to mute
    if (this.isUserMuted && this.isMuted) return;
    this.isMuted = false;

    this.fadeGlobalVolume(1, fadeDuration);
  };

  mute = (fadeDuration?: number, isFromUser = false) => {
    if (this.isMuted) return;

    if (isFromUser) this.isUserMuted = true;
    this.isMuted = true;

    this.fadeGlobalVolume(0, fadeDuration);
  };

  toggleMute = (fadeDuration: number, isFromUser: boolean) => {
    if (this.isMuted) this.unmute(fadeDuration, isFromUser);
    else this.mute(fadeDuration, isFromUser);

    return this.isMuted;
  };

  private load = () => {
    if (this.isReady) return;

    // Only load sounds that aren't cached yet
    Object.keys(SOUNDS_DATA).filter((key) => !this.cache[key]).forEach((key) => {
      this.cache[key] = new Sound(
        SOUNDS_DATA[key],
        this.onSoundLoaded
      );
    });
  };

  private unlockAudio = () => {
    if (!this.isReady) return;
    this.isUnlocked = true;

    if (IS_DEBUG) console.log('AudioManager -- unlockAudio');
    this.eventManager.trigger('unlock');
    removeEventListeners(window, 'click touchend', this.unlockAudio);
  };

  private fadeGlobalVolume = (volume: number, fadeDuration?: number) => {
    const duration = fadeDuration ?? DEFAULT_FADE_DURATION;

    if (this.muteTween) {
      this.muteTween.kill();
      this.muteTween = null;
    }

    if (duration) {
      gsap.to(this, {
        duration,
        globalVolume: volume,
        onComplete: () => {
          this.muteTween = null;
        }
      });
    } else {
      this.globalVolume = volume;
    }
  };

  private onSoundLoaded = () => {
    if (this.isReady) return;
    this.soundsLoaded++;

    const progress = this.soundsLoaded / this.soundsCount;
    this.eventManager.trigger('loadProgress', progress);
    if (this.onLoadProgress) this.onLoadProgress(progress);

    if (this.soundsLoaded === this.soundsCount) {
      this.onLoadAllComplete();

      if (!this.isUnlocked)
        addEventListeners(window, 'click touchend', this.unlockAudio);
    }
  };

  private onLoadAllComplete = () => {
    this.isReady = true;
    if (IS_DEBUG) console.log('AudioManager -- onLoadAllComplete');

    this.eventManager.trigger('loadComplete');
    if (this.onLoadComplete) this.onLoadComplete();

    this.onLoadProgress = null;
    this.onLoadComplete = null;
  };
}

export default new AudioManager();
