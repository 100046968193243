import { mountStoreDevtool } from 'simple-zustand-devtools';

import { isBrowser } from 'u9/utils/platform';
import { getUrlParams } from 'utils/url';

import useAudioStore from './audio';
import useCardScreenStore from './cardScreen';
import useClubhouseStore from './clubhouse';
import useCopyStore from './copy';
import useGlobalStore from './global';
import useLoaderStore from './loader';
import usePlaylistsStore from './playlists';
import useHotspotOverlayStore from './hotspotOverlay';

function addStoreDevtool(name: string, store) {
  const separateRoot = document.createElement('div');
  separateRoot.id = `devtools-${name}`;
  document.body.appendChild(separateRoot);
  mountStoreDevtool(name, store, separateRoot);
}

const setupStoreDevTools = () => {
  if (isBrowser() && (process.env.IS_DEBUG || getUrlParams().get('debug'))) {
    addStoreDevtool('AudioStore', useAudioStore);
    addStoreDevtool('CardScreenStore', useCardScreenStore);
    addStoreDevtool('ClubhouseStore', useClubhouseStore);
    addStoreDevtool('CopyStore', useCopyStore);
    addStoreDevtool('GlobalStore', useGlobalStore);
    addStoreDevtool('PlaylistsStore', usePlaylistsStore);
    addStoreDevtool('HotspotOverlayStore', useHotspotOverlayStore);
  }
};

export {
  useAudioStore,
  useCardScreenStore,
  useClubhouseStore,
  useCopyStore,
  useGlobalStore,
  usePlaylistsStore,
  useHotspotOverlayStore,
  setupStoreDevTools,
  useLoaderStore
};
