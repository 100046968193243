import create, { UseStore } from 'zustand';

import { HeadProps } from 'u9/components/Head/Head';
import { isStorybook } from 'u9/utils/platform';
import {
  placeholderClubhouseCopy,
  placeholderExperienceCopy,
  placeholderGlobalCopy,
  placeholderHeadCopy
} from './copy.data';
import { ClubhouseCopy, ExperienceCopy, GlobalCopy } from './copy.types';

export interface CopyStoreType {
  copy: {
    head: HeadProps;
    global: GlobalCopy;
    experience: ExperienceCopy;
    clubhouses: {
      seoul: ClubhouseCopy;
      berlin: ClubhouseCopy;
      shanghai: ClubhouseCopy;
    }
  };
  setCopy(patch: Record<string, any>): void;
}

const useCopyStore: UseStore<CopyStoreType> = create((set) => {
  return {
    copy: {
      head: isStorybook() ? placeholderHeadCopy : null,
      global: isStorybook() ? placeholderGlobalCopy : null,
      experience: isStorybook() ? placeholderExperienceCopy : null,
      clubhouses: {
        seoul: isStorybook() ? placeholderClubhouseCopy : null,
        berlin: isStorybook() ? placeholderClubhouseCopy : null,
        shanghai: isStorybook() ? placeholderClubhouseCopy : null
      }
    },

    setCopy: (patch) => {
      set(({ copy }) => ({
        copy: {
          ...copy,
          ...patch
        }
      }));
    }
  }
});

export default useCopyStore;
